import { createSlice } from "@reduxjs/toolkit";
import { createVirtualAccount, createWallet, fetchExchangeConfig, fetchVirtualAccount, fetchVirtualBanks, fetchWithdrawalFees, fetchWithdrawalSettings, fundWallet, getFiatCurrencies, getWalletBalance, InitiateDepositAccount, InitiateExchange, withdrawCrypto, withdrawToBank } from "./walletActions";

const initialState = {
  id:"",
  loading: false,
  settingsLoading: false,
  loadingWallet: false,
  dmOpen:false,
  dmDoneOnce:false,
  currency: "",
  token: "",
  balance: "", 
  country_tag: "",
  data: {},
  loadingCurrency: false,
  fiatCurrencies: {},
  settings: {},
  virtualAccount: null,
  exchangeConfig: [],
  banks: [],
  depositAccount: {},
  fees: {}
}




const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    closeDmOpen: (state, action) => {
      state.dmOpen = false;
    },
    openDmOpen: (state, action) => {
      state.dmOpen = true;
    },
    setDmDoneOnce: (state, action) => {
      state.dmDoneOnce = true;
    },
    clearDepositAccount: (state, action) => {
      state.depositAccount = {};
    }
  },
  extraReducers: {
    // =============================== THE EXTRA REDUCERS HANDLING GET WALLET BALANCE

    [createWallet.pending]: (state, { payload }) => {
      state.loadingWallet = true;
    },
    [createWallet.fulfilled]: (state, { payload }) => {
      state.loadingWallet = false;
      // state.data = payload.data;
    },
    [createWallet.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    // =============================== THE EXTRA REDUCERS HANDLING GET WALLET BALANCE

    [getWalletBalance.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getWalletBalance.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
    },
    [getWalletBalance.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    // =============================== THE EXTRA REDUCERS HANDLING GET VIRTUAL ACCOUNT 
    [fetchVirtualAccount.pending]: (state, { payload }) => {
    },
    [fetchVirtualAccount.fulfilled]: (state, { payload }) => {
      state.virtualAccount = payload.data.virtualAccount;
    },
    [fetchVirtualAccount.rejected]: (state, { payload }) => {
    },

      // =============================== THE EXTRA REDUCERS HANDLING CREATE VIRTUAL ACCOUNT 
      [createVirtualAccount.pending]: (state, { payload }) => {
      },
      [createVirtualAccount.fulfilled]: (state, { payload }) => {
        state.virtualAccount = payload.data;
      },
      [createVirtualAccount.rejected]: (state, { payload }) => {
      },

       // =============================== THE EXTRA REDUCERS HANDLING INITIATE DEPOSIT ACCOUNT 
       [InitiateDepositAccount.pending]: (state, { payload }) => {
      },
      [InitiateDepositAccount.fulfilled]: (state, { payload }) => {
        state.depositAccount = payload.data;
      },
      [InitiateDepositAccount.rejected]: (state, { payload }) => {
      },

         // =============================== THE EXTRA REDUCERS HANDLING FETCH VIRTUAL BANKS 
    [fetchVirtualBanks.pending]: (state, { payload }) => {
    },
    [fetchVirtualBanks.fulfilled]: (state, { payload }) => {
      state.banks = payload.data;
    },
    [fetchVirtualBanks.rejected]: (state, { payload }) => {
    },

    // =============================== THE EXTRA REDUCERS HANDLING FUNDING WALLET

    [fundWallet.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [fundWallet.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
    },
    [fundWallet.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    // =============================== THE EXTRA REDUCERS HANDLING WITHDRAWAL TO BANK ACCOUNT

    [withdrawToBank.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [withdrawToBank.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
    },
    [withdrawToBank.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    // =============================== THE EXTRA REDUCERS HANDLING GET FIAT CURRENCIES

    [getFiatCurrencies.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getFiatCurrencies.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.fiatCurrencies = payload.data;
    },
    [getFiatCurrencies.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    // =============================== THE EXTRA REDUCERS HANDLING GET FIAT CURRENCIES

    [withdrawCrypto.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [withdrawCrypto.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.fiatCurrencies = payload.data;
    },
    [withdrawCrypto.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    // =============================== THE EXTRA REDUCERS HANDLING WITHDRAWAL SETTINGS

    [fetchWithdrawalSettings.pending]: (state, { payload }) => {
      state.settingsLoading = true;
    },
    [fetchWithdrawalSettings.fulfilled]: (state, { payload }) => {
      state.settingsLoading = false;
      state.settings = payload.data.data;
    },
    [fetchWithdrawalSettings.rejected]: (state, { payload }) => {
      state.settingsLoading = false;
    },

      // =============================== THE EXTRA REDUCERS HANDLING WITHDRAWAL FEES

      [fetchWithdrawalFees.pending]: (state, { payload }) => {
      },
      [fetchWithdrawalFees.fulfilled]: (state, { payload }) => {
        state.fees = payload.data.data.settings;
      },
      [fetchWithdrawalFees.rejected]: (state, { payload }) => {
        state.settingsLoading = false;
      },
      

    // =============================== THE EXTRA REDUCERS FOR EXCHANGE CONFIG
    [fetchExchangeConfig.pending]: (state, { payload }) => {
    },
    [fetchExchangeConfig.fulfilled]: (state, { payload }) => {
      state.exchangeConfig = payload.data.data;
    },
    [fetchExchangeConfig.rejected]: (state, { payload }) => {
    },

    // =============================== THE EXTRA REDUCERS FOR CURRENCY EXCHANGE
    [InitiateExchange.pending]: (state, { payload }) => {
    },
    [InitiateExchange.fulfilled]: (state, { payload }) => {
    },
    [InitiateExchange.rejected]: (state, { payload }) => {
    },
  },
});

export default walletSlice.reducer;
export const {
  closeDmOpen,
  openDmOpen,
  setDmDoneOnce,
  clearDepositAccount
 } = walletSlice.actions;