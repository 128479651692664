import {useEffect} from 'react';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";


export const RecaptchaVerifier = ({ onVerify, action }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
  
    useEffect(() => {
      if (!executeRecaptcha) {
        console.log("reCAPTCHA not yet available");
        return;
      }
  
      executeRecaptcha(action).then((token) => {
        onVerify(token);
      });
    }, [executeRecaptcha, onVerify, action]);
  
    return null;
  };
  