import React, { useState } from "react";
import style from "./wallet.module.css";
import logo from "../../assets/images/logo-white.png";
import {
  AddCircle,
  ArrowCircleDown,
  ArrowDown2,
  Eye,
  EyeSlash,
} from "iconsax-react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { createWallet, getWalletBalance } from "../../store/wallet/walletActions";
import nigeria from "../../assets/images/ng.svg";
import ghana from "../../assets/images/gh.svg";
import usa from "../../assets/images/us.svg";
import Modal from "../modal/Modal";
import Lottie from "lottie-react";
import loader from "../../assets/lottie/loader.json";

// const currencies = [
//   { id: 1, title: "NGN" },
//   { id: 2, title: "GHS" },
// ];


const WalletCard = () => {
  const [walletOpen, setWalletOpen] = useState(false);
  const [fiatWalletId, setFiatWalletId] = useState("");
  const [visible, setVisible] = useState(true);
  const [wallet, setWallet] = useState("");
  const [loadWallet, setLoadWallet] = useState("");
  const [openWallet, setOpenWallet] = useState(false);
  const [openAddWallet, setOpenAddWallet] = useState(false);
  const [currNo, setCurrNo] = useState(0);
  const [openDrop, setOpenDrop] = useState(false);
  const [availableWallets, setAvailableWallets] = useState([]);
  const [ownedWallets, setOwnedWallets] = useState([]);
  const [addDisabled, setAddDisabled] = useState(true);

  const dispatch = useDispatch();
  // const userData = useSelector(state => state.auth.user);
  const walletData = useSelector((state) => state.wallet.data);
  const loadingWallet = useSelector((state) => state.wallet.loadingWallet);
  const addWallets = useSelector((state) => state.wallet.fiatCurrencies.data ? state.wallet.fiatCurrencies.data.fiat : "");

  const handleWallet = (e) => {
    setWallet(e.target.textContent);

    setWalletOpen(false);

  };

  
  const handleProceedToAddWallet = (e) => {
    e.preventDefault();
    dispatch(createWallet({ fiatWalletId }))
    setOpenAddWallet(false)
  };
  
  const toggleWallet = () => {
    setOpenWallet(!openWallet);
  };
  
  const getDifference = (a, b) => {
    return a.filter((item1) => {
      return !b.some(item2 => {
        return item1.id === item2.id
      });
    });
  };
  
  const handleAddWallet = () => {
    
  };
  
  useEffect(() => {
    if (loadingWallet === true) {
      setLoadWallet(true)
    } else {
      setLoadWallet(false)
    }
  }, [loadingWallet]);


  useEffect(() => {
    setOwnedWallets(
      walletData.fiatWallets &&
        walletData.fiatWallets.map((item) => {
          return { id: item.Currency.id, code: item.Currency.code };
        })
    );
    setTimeout(() => {
      setAvailableWallets(getDifference(addWallets, ownedWallets))
     }, 2000)
  }, [walletData]);
  
  
  
  

  useEffect(() => {
    if (fiatWalletId !== "") {
      setAddDisabled(false)
    } else {
      setAddDisabled(true)
    }

    if (openAddWallet === false) {
      setFiatWalletId("")
      setWallet("")
    }
  }, [fiatWalletId, openAddWallet]);

  useEffect(() => {
    dispatch(getWalletBalance());
  }, []);



  return (
    <div className={style.walletCard}>
      <Modal modalOpen={loadWallet}>
        <div className={style.lottieWrapDrop}>
          <Lottie
            style={{
              width: "70px",
              height: "70px",
              color: "#fff",
              padding: "0px",
              margin: "0px",
            }}
            animationData={loader}
            loop={true}
          />
        </div>
      </Modal>
      <Modal
        canClose={true}
        modalOpen={openAddWallet}
        setModalOpen={setOpenAddWallet}
      >
        <div className={style.addWalletForm}>
          <div className={style.awfTop}>
            <h2>Add Wallet</h2>
            <p>
              Please select a wallet you would like to add from the options
              below
            </p>
          </div>
          <form action="">
            <div className={style.labelPut}>
              <label htmlFor="acct_type"> Select Wallet</label>
              <div className={style.select}>
                <div onClick={toggleWallet} className={style.selectInput}>
                  <input
                    name="acctType"
                    type="text"
                    disabled={true}
                    value={wallet}
                    placeholder="Please select a Wallet"
                  />
                  <ArrowDown2
                    style={{
                      transform:
                        openWallet === true
                          ? "translateY( -50%) rotate(-540deg)"
                          : "translateY( -50%) rotate(0deg)",
                    }}
                    className={style.dropDown}
                    size="20"
                    color="#909090"
                    variant="Bold"
                  />
                </div>
                <div
                  style={{
                    height: openWallet === true ? "fit-content" : "0px",
                  }}
                  className={style.option}
                >
                  {availableWallets && availableWallets.length > 0 ? (
                    availableWallets.map((item) => (
                      <p
                        key={item.id}
                        onClick={(e) => {
                          handleWallet(e);
                          setFiatWalletId(item.id);
                          setOpenWallet(false);
                        }}
                      >
                        {item.code} Wallet
                      </p>
                    ))
                  ) : (
                    <p>You can no longer create any wallets</p>
                  )}
                </div>
                {/* <input type="text" placeholder='Enter Last Name' /> */}
              </div>
            </div>
            <button
              onClick={handleProceedToAddWallet}
              disabled={addDisabled}
              className={style.save2}
            >
              Add Wallet
            </button>
          </form>
        </div>
      </Modal>
      <div className={style.walTop}>
        <img
          className={style.logoImg}
          src={logo}
          alt="astroafrica.site/home.svg"
        />
      </div>
      <div className={style.walBottom}>
        <p>
          {walletData.fiatWallets && walletData.fiatWallets.length
            ? "WALLET BALANCE"
            : "ADD A WALLET "}
        </p>
        {walletData.fiatWallets && walletData.fiatWallets.length ? (
          <div className={style.balance}>
            <p>
              {visible === true
                ? `${
                    walletData.fiatWallets
                      ? new Intl.NumberFormat("NG", {
                          style: "currency",
                          currency:
                            walletData.fiatWallets &&
                            walletData.fiatWallets[currNo]
                              ? walletData.fiatWallets[currNo].Currency.code
                              : "NGN",
                        }).format(
                          walletData.fiatWallets[currNo]
                            ? walletData.fiatWallets[currNo].balance
                            : ""
                        )
                      : "0.000"
                  }`
                : "XXXXXXXXX"}
            </p>
            {visible === true ? (
              <EyeSlash
                onClick={() => {
                  setVisible(!visible);
                }}
                className={style.walEye}
                color="#fff"
                variant="Bulk"
              />
            ) : (
              <Eye
                onClick={() => {
                  setVisible(!visible);
                }}
                className={style.walEye}
                color="#fff"
                variant="Bulk"
              />
            )}
          </div>
        ) : (
          <p>You have no wallets</p>
        )}
        <div className={style.walletTag}>




          {/* ============= CONDITION FOR DISPLAYING THE WALLET NAME AND IMAGE */}

          {walletData.fiatWallets && walletData.fiatWallets.length ? (
            <div className={style.countryTag}>
              <div className={style.ctImg}>
                {walletData.fiatWallets &&
                  walletData.fiatWallets.length &&
                  walletData.fiatWallets[currNo].Currency.code === "NGN" && (
                    <img src={nigeria} alt="astroafrica.site/country.svg" />
                  )}
                {walletData.fiatWallets &&
                  walletData.fiatWallets.length &&
                  walletData.fiatWallets[currNo].Currency.code === "GHS" && (
                    <img src={ghana} alt="astroafrica.site/country.svg" />
                  )}
                {walletData.fiatWallets &&
                  walletData.fiatWallets.length &&
                  walletData.fiatWallets[currNo].Currency.code === "USD" && (
                    <img src={usa} alt="astroafrica.site/country.svg" />
                  )}
              </div>
              <p className={style.ctText}>
                {walletData.fiatWallets && walletData.fiatWallets.length
                  ? walletData.fiatWallets[currNo].Currency.code
                  : ""}
              </p>
              <div
                onClick={() => {
                  setOpenDrop(!openDrop);
                }}
                className={style.ctDown}
              >
                <ArrowCircleDown
                  className={style.ctCon}
                  size="32"
                  variant="Bold"
                />
                <div className={style.ctCol}></div>
              </div>
              <div
                style={{
                  height: openDrop === true ? "fit-content" : "0",
                  maxHeight: "140px",
                }}
                className={style.ctDropDown}
              >
                {walletData.fiatWallets ? (
                  walletData.fiatWallets.map((item, idx) => (
                    <div
                      key={idx}
                      onClick={() => {
                        setCurrNo(idx);
                        setOpenDrop(false);
                      }}
                      className={style.countryTag2}
                    >
                      <div className={style.ctImg}>
                        {item.Currency.code === "NGN" && (
                          <img src={nigeria} alt="astroafrica.com.flagimg" />
                        )}
                        {item.Currency.code === "GHS" && (
                          <img src={ghana} alt="astroafrica.com.flagimg" />
                        )}
                        {item.Currency.code === "USD" && (
                          <img src={usa} alt="astroafrica.com.flagimg" />
                        )}
                      </div>
                      <p className={style.ctText}>{item.Currency.code}</p>
                    </div>
                  ))
                ) : (
                  <p>No wallet, add new</p>
                )}
              </div>
            </div>
          ) : "" }



          {/* ============= PLUS BUTTON */}
          <div
            onClick={() => {
              setOpenAddWallet(true);
              handleAddWallet();
            }}
            className={style.ctAdd}
            style={{
              display:
                walletData.fiatWallets && walletData.fiatWallets.length === 3
                  ? "none"
                  : "flex",
            }}
          >
            <AddCircle className={style.ctaCon} size="32" variant="Bold" />
            <div className={style.ctCol}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletCard;