import React from "react";
import style from "./modal.module.css";
import close_icon from '../../assets/images/close-circle.svg'

export const ModalChild = ({ children, modalOpen, title, setModalOpen, canClose }) => {
  return (
    <div
      className={modalOpen === false ? style.modal : style.modalFade}
      // style={{
      //   // display: modalOpen === true ? "flex" : "none"
      // }}
    >
      <div className={style.popUpModalChild}>
        {canClose === true ? (
          <div className={style.closeModalConWrap} style={{justifyContent: 'flex-end'}}>
             <img 
               className={style.closeModalCon}
               onClick={() => {
                setModalOpen(false);
              }}
               src={close_icon} alt="close icon" />
          </div>
        ) : (
          <></>
        )}
        {children}
      </div>
    </div>
  );
};


const Modal = ({ children, modalOpen, title="",minWidth = "30%", setModalOpen, canClose }) => {
  return (
    <div
      className={modalOpen === false ? style.modal : style.modalFade}
      // style={{
      //   // display: modalOpen === true ? "flex" : "none"
      // }}
    >
      <div className={`${style.popUp}`} style={{ minWidth: minWidth}}>
        {canClose === true ? (
          <div className={style.closeModalConWrap}>
              <p>{title}</p>
              <img 
               className={style.closeModalCon}
               onClick={() => {
                setModalOpen(false);
              }}
               src={close_icon} alt="close icon" />
          </div>
        ) : (
          <></>
        )}
        {children}
      </div>
    </div>
  );
};

export default Modal;
