import { ArrowDown2, ArrowLeft2, Copy, InfoCircle } from "iconsax-react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setHeader } from "../../store/settings/settingsSlice";
import IfEmpty from "../ifEmpty/IfEmpty";
import Modal from "../modal/Modal";
import Prompt from "../prompt/Prompt";
import style from "./crypto-trade.module.css";
import Lottie from "lottie-react";
import loader from "../../assets/lottie/loader.json";
// import barcode from "../../assets/images/barcode.png";
import doneDots from "../../assets/images/doneDots.svg";
import cogoToast from "cogo-toast";
import {
  getAllCoins,
  getCoinById,
  tradeNewCoin,
} from "../../store/crypto/cryptoActions";
import QRCode from "react-qr-code";
import {
  clearCoinNetwork,
  clearCoins,
  clearNewcoinData,
  clearRates,
  filterCoins,
} from "../../store/crypto/cryptoSlice";
import { browserName } from "react-device-detect";
import { createCryptoWallet } from "../../store/settings/settingsActions";

const CryptoTrade = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [coinTypeId, setCoinTypeId] = useState("");
  const [coinNetworkId, setCoinNetworkId] = useState("");
  const [amount, setAmount] = useState("");
  const [rate, setRate] = useState(0);
  const [coinTypeRate, setCoinTypeRate] = useState(0);
  const [usdValue, setUsdValue] = useState(0);
  const [payoutAmount, setPayoutAmount] = useState(0.0);
  const [walletId, setWalletId] = useState("");
  const [codeName, setCodeName] = useState("");
  const [creditWalletId, setCreditWalletId] = useState("");

  const [wallet, setWallet] = useState("");
  const [weBuy, setWeBuy] = useState(0);
  const [openWallet, setOpenWallet] = useState(false);
  const [cardCat, setCardCat] = useState("");
  const [cardNetwork, setCardNetwork] = useState("");
  const [openCardCat, setOpenCardCat] = useState(false);
  const [openNetworkCat, setOpenNetworkCat] = useState(false);
  const [openBarcode, setOpenBarcode] = useState(false);
  const [doneDisabled, setDoneDisabled] = useState(true);
  const [coinRateLoading, setCoinRateLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [barLoading, setBarLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [coinLoading, setCoinLoading] = useState(false);
  const [openPrompt, setOpenPrompt] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [coinId, setCoinId] = useState(null)
  const [coinName, setCoinName] = useState("")
  const [coinNetworkName, setCoinNetworkName] = useState("")
  const [btnLoader, setBtnLoader] = useState(false)

  const copyRef = useRef();
  const copyRef1 = useRef();
  const copyRef2 = useRef();

  const walletData = useSelector((state) => state.wallet.data);
  const isLoading = useSelector((state) => state.crypto.barLoading);
  const {coins,coinNetworks} = useSelector((state) => state.crypto);
  const coinRates = useSelector((state) => state.crypto.coinRates.coinRates);
  const isCoinLoading = useSelector((state) => state.crypto.coinLoading);
  const isBarcodeLoading = useSelector((state) => state.crypto.barcodeLoading);
  const newcoinData = useSelector((state) => state.crypto.newcoinData);
  const isCoinRateLoading = useSelector(
    (state) => state.crypto.coinRateLoading
  );

  // const dollarWallet = wwalletData && walletData.fiatWallets.filter(
  //   (item) => item.Currency.code === "USD"
  // );

  const cta1 = () => {
    setOpenPrompt(false);
  };

  const handleWallet = (e) => {
    setOpenWallet(false);
    setWallet(e.target.textContent);
  };

  const toggleWallet = () => {
    setOpenWallet((prevWallet) => {
      if (prevWallet === false) {
        return true;
      } else if (prevWallet === true) {
        return false;
      }
    });
  };

  const toggleCardCat = () => {
    setOpenCardCat((prevCardCat) => {
      if (prevCardCat === false) {
        return true;
      } else if (prevCardCat === true) {
        return false;
      }
    });
  };

  const toggleCardNetwork = () => {
    setOpenNetworkCat((prevCardCat) => {
      if (prevCardCat === false) {
        return true;
      } else if (prevCardCat === true) {
        return false;
      }
    });
  };

  const handleCardCat = (title) => {
    setCardCat(title);
    setOpenCardCat(false);
  };

  const copyAddress = () => {
    let refToCopy = copyRef.current.textContent;
    // refToCopy.select();
    navigator.clipboard.writeText(refToCopy);
    cogoToast.success("Copied to Clipboard", {
      position: "top-right",
    });
    console.log(refToCopy);
  };
  const copyAmount = () => {
    let refToCopy = copyRef1.current.textContent;
    // refToCopy.select();
    navigator.clipboard.writeText(refToCopy);
    cogoToast.success("Copied to Clipboard", {
      position: "top-right",
    });
    console.log(refToCopy);
  };
  const copyPayoutAmount = () => {
    let refToCopy = copyRef2.current.textContent;
    // refToCopy.select();
    navigator.clipboard.writeText(refToCopy);
    cogoToast.success("Copied to Clipboard", {
      position: "top-right",
    });
    console.log(refToCopy);
  };

  const handleProceed = (e) => {
    e.preventDefault();
    setOpenBarcode(true);
    dispatch(
      tradeNewCoin({
        coinTypeId,
        coinNetworkId,
        amount: usdValue,
        coinTypeRate,
        payoutAmount,
        creditWalletId,
        coinAmount: amount
      })
    );
  };

  const handleCoinSelect = (item) => {
    setCardCat(item.name);
    setCoinName(item.name)
    setCoinId(item.id)
    setOpenCardCat(false);
    setCoinTypeId(item.id);
    setRate(item.usd_rate);
    dispatch(filterCoins(item.id));
    dispatch(getCoinById({ coinTypeId: item.id }));
    setCardNetwork("")
  };

  const toggleWalletGenerateModal = (item) =>{
    if(item.isActive){
      setCardNetwork(item.network_name);
      setOpenNetworkCat(false);
      setCoinNetworkId(item.id)
    }
    else{
      setCoinNetworkId(item.id)
      setCoinNetworkName(item.network_name)
      setOpenInfo(!openInfo)
      setOpenNetworkCat(false);
    }
  }


  const generateWallet = () =>{
    setBtnLoader(true)
    dispatch(createCryptoWallet({
        coinTypeId: coinId,
        coinNetworkId: coinNetworkId
    })).then((res)=>{
        if(res?.type === "createCryptoWallet/fulfilled"){
            setBtnLoader(false)
            setOpenInfo(false)
            dispatch(getAllCoins())
            setCardCat("")
            dispatch(clearCoinNetwork())
        }
        else if(res?.type === "createCryptoWallet/rejected"){
            setBtnLoader(false)
        }
    })
  }

  useEffect(() => {
    if (coinRates) {
      let newArr = [];
      let newMinArr = [];
      let newMaxArr = [];
      
      for (let i = 0; i < coinRates.length; i++) {
        if (
          walletId === coinRates[i].fiat_currency_id &&
          coinTypeId === coinRates[i].coin_type_id &&
          parseInt(usdValue) <= coinRates[i].maximum_usd_Value &&
          parseInt(usdValue) >= coinRates[i].minimum_usd_value
        ) {
          setCoinTypeRate(coinRates[i].rate);
          break;
        } else if (
          coinRates &&
          coinRates.length &&
          walletId === coinRates[i].fiat_currency_id &&
          coinTypeId === coinRates[i].coin_type_id
        ) {
          newArr.push(coinRates[i]);
          function getArr() {
            return newArr.map((item) => item.minimum_usd_value);
          }
          function getRateArr() {
            return newArr.map((item) => item.rate);
          }
          function getMinArr() {
            newMinArr = Math.min(...getArr());
          }
          function getMaxArr() {
            newMaxArr = Math.max(...getArr());
          }
          getMinArr();
          getMaxArr();

          if (parseInt(usdValue) < newMinArr) {
            newArr.filter((item) => {
              if (parseInt(item.minimum_usd_value) === newMinArr) {
                setCoinTypeRate(item.rate);
              }
            });
            console.log("smaller");
          } else if (parseInt(usdValue) > newMaxArr) {
            newArr.filter((item) => {
              if (parseInt(item.maximum_usd_value) === newMaxArr) {
                setCoinTypeRate(item.rate);
              }
            });
            console.log("bigger");
          }
        }
      }

      console.log(usdValue);
      console.log(newMinArr);
      console.log(newMaxArr);
      console.log(newArr);
      console.log(
        parseInt(coinTypeRate),
        parseInt(weBuy),
        walletId,
        coinTypeId,
        parseInt(amount)
      );
    }

    if (
      amount === NaN ||
      amount === 0 ||
      amount === "" ||
      usdValue === NaN ||
      usdValue === 0 ||
      usdValue === ""
    ) {
      setCoinTypeRate(0);
    }
  }, [coinTypeId, amount, rate, payoutAmount, walletId, usdValue]);

  useEffect(() => {
    setPayoutAmount(parseInt(coinTypeRate) * usdValue);
  }, [
    coinTypeId,
    amount,
    rate,
    payoutAmount,
    walletId,
    usdValue,
    coinTypeRate,
  ]);

  useEffect(() => {
    if (isLoading === true) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    if (isCoinLoading === true) {
      setCoinLoading(true);
    } else {
      setCoinLoading(false);
    }

    if (isCoinRateLoading === true) {
      setCoinRateLoading(true);
    } else {
      setCoinRateLoading(false);
    }

    if (isBarcodeLoading === true) {
      setBarLoading(true);
    } else {
      setBarLoading(false);
    }
  }, [isLoading, isCoinLoading, isCoinRateLoading, isBarcodeLoading]);

  useEffect(() => {
    dispatch(setHeader("Sell Crypto"));
  }, []);

  useEffect(() => {
    if (wallet !== "" && cardCat !== "" && cardNetwork !== "" && amount !== "" && amount !== 0) {
      setDoneDisabled(false);
    } else {
      setDoneDisabled(true);
    }
  }, [wallet, cardCat,cardNetwork, amount]);

  useEffect(() => {
    let newUsdValue = Math.round(amount * rate);
    setUsdValue(newUsdValue);
  }, [rate, amount]);

  useEffect(() => {
    let newAmount = usdValue / rate;
    setAmount(newAmount);
  }, [rate, usdValue]);

  useEffect(() => {
    setAmount(0);
    setUsdValue(0);
  }, [setCoinTypeId]);

  useEffect(() => {
    dispatch(setHeader("Sell Crypto"));
  }, []);

  // HANDLE WALLET CLOSE ON CLICK OUT
  useEffect(() => {
    let handler = (e) => {
      if (e.target.id !== "wallet") {
        setOpenWallet(false);
      } else if (e.target.id === "wallet") {
        toggleWallet();
      }
      if (e.target.id === "walletDrop") {
        setOpenWallet(true);
      }
      // console.log(currRef.current.name)
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  // HANDLE CARD CAT CLOSE ON CLICK OUT
  useEffect(() => {
    let handler = (e) => {
      if (e.target.id !== "cardCat") {
        setOpenCardCat(false);
      } else if (e.target.id === "cardCat") {
        toggleCardCat();
      }
      if (e.target.id === "cardCatDrop") {
        setOpenCardCat(true);
      }
      // console.log(currRef.current.name)
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  // Handle card network close on click out
  useEffect(() => {
    let handler = (e) => {
      if (e.target.id !== "cardNetwork") {
        setOpenNetworkCat(false);
      } else if (e.target.id === "cardNetwork") {
        toggleCardNetwork();
      }
      if (e.target.id === "cardNetworkDrop") {
        setOpenNetworkCat(true);
      }
      // console.log(currRef.current.name)
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  return (
    <div className={style.tradeDetails}>
      {/* <Prompt
        open={dollarWallet && dollarWallet.length <= 0 ? true : false}
        message="We noticed you do not have a USD wallet, please create a USD wallet to use this feature"
        cta1={() => {navigate('/wallets')}}
        cta2={() => {navigate('/dashboard')}}
        cta1Text="Proceed to Create"
        cta2Text="Go to Dashboard"
      /> */}
      <Modal canClose={false} modalOpen={confirm} setModalOpen={setConfirm}>
        <div className={style.cryptal}>
          <img src={doneDots} alt="astroafrica.site" />
          <h3
            style={{
              fontSize: "24px",
              fontWeight: "600",
              color: "#40196d",
              textAlign: "center",
              margin: "1.4rem 0 .4rem 0",
            }}
          >
            Trade Initiated
          </h3>
          <p>
            Your trade has been initiated and is being processed. You’ll receive
            a feedback shortly
          </p>
          <button
            onClick={() => {
              navigate("/crypto");
              // window.location.pathname = "/crypto"
              dispatch(clearCoins());
              dispatch(clearCoinNetwork())
              dispatch(clearRates());
              dispatch(clearNewcoinData());
              setOpenBarcode(false);
              setWallet("");
              setCardCat("");
              setCardNetwork("")
              setAmount("");
              setPayoutAmount(0);
              setConfirm(false);
            }}
            style={{ margin: " 0 0 0 0 " }}
            className={style.save}
          >
            Start Another Trade
          </button>
          <p
            onClick={() => {
              navigate("/dashboard");
            }}
            className={style.cryptalPee}
          >
            Back to Dashboard{" "}
          </p>
        </div>
      </Modal>

      <Modal canClose={true} modalOpen={openInfo} setModalOpen={setOpenInfo}>
        <div style={{marginTop: '30px'}}>
          <h4 style={{color: '#40196D', fontWeight: 600, fontSize: '20px'}}>Wallet Address Not Generated</h4>
          <p style={{color:'#B6B6B6', fontSize: '12px', textAlign: 'center', maxWidth:  '450px', margin: 'auto'}}>
          To sell {coinName ? `${coinName}(${coinNetworkName.toUpperCase()})` : 'this coin'}, you need to generate your unique wallet address. <br/> This address will be used when you wish to sell the coin
          </p>

          <div style={{display: 'flex', justifyContent: 'center', gap:'10px'}}>
             <button
                className={style.save}
                style={{backgroundColor: '#EDDEFF', color: '#40196D'}}
                onClick={() =>setOpenInfo(false)}
              >
                Close
              </button>
              <button
               disabled={btnLoader}
                className={style.save}
                onClick={() => generateWallet()}
              >
                Generate Address
              </button>
          </div>
        </div>
      </Modal>

      <Prompt
        message="Your cable network recharge was successful"
        cta1={cta1}
        open={openPrompt}
        cta1Text="OK"
        cta2Text="CANCEL"
      />
      <div className={style.top}>
        {openBarcode ? (
          <ArrowLeft2
            style={{ cursor: "pointer" }}
            onClick={() => {
              setOpenBarcode(false);
            }}
            size="32"
          />
        ) : (
          ""
        )}
        <h5>Trade Details</h5>
      </div>
      <div className={style.wrapper}>
        <div className={style.left}>
          <div className={style.form}>
            <form action="">
              <div
                style={{ display: openBarcode === true ? "none" : "block" }}
                className={style.labelPut}
              >
                <label htmlFor="acct_type"> Payout Wallet</label>
                <div className={style.select}>
                  <div id="wallet"
                    name="acctType" className={style.selectInput}>
                    <input
                      
                      type="text"
                      disabled={browserName === "Firefox" ? false : true}
                      value={wallet}
                      placeholder="Please select a Wallet"
                      style={{pointerEvents: "none"}}
                    />
                    <ArrowDown2
                      style={{
                        transform:
                          openWallet === true
                            ? "translateY( -50%) rotate(-540deg)"
                            : "translateY( -50%) rotate(0deg)",
                        pointerEvents: "none",
                      }}
                      className={style.dropDown}
                      size="20"
                      color="#909090"
                      variant="Bold"
                    />
                  </div>
                  <div
                    style={{
                      height: openWallet === true ? "fit-content" : "0px",
                    }}
                    className={style.option}
                  >
                    {walletData.fiatWallets ? (
                      walletData.fiatWallets.map((item, index) => (
                        <p
                          id="walletDrop"
                          key={item.id}
                          style={{
                            display:
                              item.Currency.code === "USD" ? "none" : "block",
                              borderBottom: walletData.fiatWallets.length - 1 === index ? "none" : "1px #90909039 solid;",
                          }}
                         
                          onClick={(e) => {
                            handleWallet(e);
                            setWeBuy(item.Currency.we_buy);
                            setCodeName(item.Currency.code);
                            setWalletId(item.Currency.id);
                            setCreditWalletId(item.id);
                            dispatch(getAllCoins());
                          }}
                        >
                          {item.Currency.code} Wallet
                        </p>
                      ))
                    ) : (
                      <p>Sorry, no wallets here</p>
                    )}
                  </div>
                  {/* <input type="text" placeholder='Enter Last Name' /> */}
                </div>
              </div>
              
              <div className={style.labelPut}>
                <label htmlFor="acct_type">
                  {openBarcode === true ? "Coin" : "Select Coin"}
                </label>
                <div className={style.select}>
                  <div id="cardCat"
                    name="acctType" className={style.selectInput}>
                    <input
                      
                      type="text"
                      disabled={browserName === "Firefox" ? false : true}
                      value={cardCat}
                      placeholder="Please select a Category"
                      style={{pointerEvents :"none"}}
                    />
                    {coinLoading === true ? (
                      <div className={style.lottieWrapDrop2}>
                        <Lottie
                          style={{
                            width: "70px",
                            height: "70px",
                            color: "#fff",
                          }}
                          animationData={loader}
                          loop={true}
                        />
                      </div>
                    ) : (
                      <>
                        {openBarcode === true ? (
                          ""
                        ) : (
                          <ArrowDown2
                            style={{
                              transform:
                                openCardCat === true
                                  ? "translateY( -50%) rotate(-540deg)"
                                  : "translateY( -50%) rotate(0deg)",
                              pointerEvents: "none",
                            }}
                            className={style.dropDown}
                            size="20"
                            color="#909090"
                            variant="Bold"
                          />
                        )}
                      </>
                    )}
                  </div>
                  <div
                    style={{
                      display: openBarcode === true ? "none" : "flex",
                      height: openCardCat === true ? "fit-content" : "0px",
                    }}
                    className={style.option}
                  >
                    {coins.length > 0
                      ? coins.map((item, index) => (
                          <p
                            id="cardCatDrop"
                            key={item.id}
                            className={style.cardItem}
                            onClick={() => {
                              handleCoinSelect(item)
                            }}
                            style={{
                              borderBottom: coins.length - 1 === index ? "none" : "1px #90909039 solid;",
                            }}
                          >
                           
                              {item.name}
                          </p>
                        ))
                      : "There was a problem loading coins, please select a wallet"}
                  </div>
                  {/* <input type="text" placeholder='Enter Last Name' /> */}
                </div>
              </div>

              <div className={style.labelPut}>
                <label htmlFor="acct_type">
                  {openBarcode === true ? "Network" : "Select Network"}
                </label>
                <div className={style.select}>
                  <div id="cardNetwork"
                    name="acctType" className={style.selectInput}>
                    <input
                      
                      type="text"
                      disabled={browserName === "Firefox" ? false : true}
                      value={cardNetwork.toUpperCase()}
                      placeholder="Please select a Network"
                      style={{pointerEvents :"none"}}
                    />
                    {coinLoading === true ? (
                      <div className={style.lottieWrapDrop2}>
                        <Lottie
                          style={{
                            width: "70px",
                            height: "70px",
                            color: "#fff",
                          }}
                          animationData={loader}
                          loop={true}
                        />
                      </div>
                    ) : (
                      <>
                        {openBarcode === true ? (
                          ""
                        ) : (
                          <ArrowDown2
                            style={{
                              transform:
                              openNetworkCat === true
                                  ? "translateY( -50%) rotate(-540deg)"
                                  : "translateY( -50%) rotate(0deg)",
                              pointerEvents: "none",
                            }}
                            className={style.dropDown}
                            size="20"
                            color="#909090"
                            variant="Bold"
                          />
                        )}
                      </>
                    )}
                  </div>
                  <div
                    style={{
                      display: openBarcode === true ? "none" : "flex",
                      height: openNetworkCat === true ? "fit-content" : "0px",
                    }}
                    className={style.option}
                  >
                    {coinNetworks.length > 0
                      ? coinNetworks.map((item) => (
                          <div
                            id="cardNetworkDrop"
                            key={item.id}
                            className={style.cardItem}
                            onClick={() => {
                              toggleWalletGenerateModal(item)
                            }}
                          >
                            <div>
                            <p className={style.cardItem_p} style={{ pointerEvents: "none" }}>
                              {" "}
                              {item.network_name.toUpperCase()}{" "}
                            </p>
                            </div>
                            <div>
                            <button
                                onClick={(e) => e.stopPropagation()}
                               className={ item.isActive ? `${style.coinBtn} ${style.coinBtn_generate}` : `${style.coinBtn} ${style.coinBtn_notgenerate}`}>
                                   {item.isActive ? "Address Generated": "Generate Address"}
                              </button>
                              </div>
                          </div>
                        ))
                      : "There was a problem loading networks, please select a coin"}
                  </div>
                  {/* <input type="text" placeholder='Enter Last Name' /> */}
                </div>
              </div>

              <div className={style.labelPut}>
                <label htmlFor="number"> USD Value </label>
                <div className={style.input}>
                  <input
                    type="number"
                    name="acct_number"
                    placeholder="Enter Amount"
                    disabled={coinRateLoading || openBarcode}
                    value={usdValue}
                    onChange={(e) => {
                      setUsdValue(e.target.value);
                    }}
                  />
                </div>
                <p>
                  1 usd = {coinTypeRate && coinTypeRate > 0 ? coinTypeRate : 0}
                  {""}
                </p>
              </div>

              <div className={style.labelPut2}>
                <InfoCircle size="24" variant="Bold" color="#F40000" />
                <p>The minimum tradable amount is $5</p>
              </div>

              <div className={style.labelPut}>
                <label htmlFor="number">
                  {" "}
                  Coin Amount<span style={{ color: "#F40000" }}>*</span>{" "}
                </label>
                <div className={style.input}>
                  <input
                    type="number"
                    name="acct_number"
                    disabled={coinRateLoading || openBarcode}
                    placeholder="Enter Amount"
                    value={parseFloat(amount).toFixed(4)}
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        setAmount("");
                      } else if (e.target.value === "") {
                        setAmount("");
                      } else {
                        setAmount(e.target.value);
                      }
                    }}
                  />
                </div>
                {coinRateLoading === true ? (
                  <p style={{ fontSize: "12px", margin: "6px 0 0 0" }}>
                    {" "}
                    Please wait while we fetch rates...{" "}
                  </p>
                ) : (
                  ""
                )}
              </div>

             

              <div className={style.labelPut}>
                <label htmlFor="number"> You Receive </label>
                <div className={style.input}>
                  <input
                    type="number"
                    name="acct_number"
                    placeholder="Enter Amount"
                    value={payoutAmount === NaN ? "" : payoutAmount}
                    disabled
                    onChange={(e) => {
                      if (e.target.value <= 0) {
                        setAmount("");
                      } else {
                        setAmount(e.target.value);
                      }
                    }}
                  />
                </div>
              </div>
              <button
                style={{
                  display: openBarcode === true ? "none" : "block",
                }}
                disabled={doneDisabled}
                onClick={handleProceed}
                className={style.save}
              >
                Proceed
              </button>

              {/* <div className={style.labelPut}>
                <label htmlFor="number"> Gift Card Image </label>
                <div className={style.input}>
                  <div className={style.cardImg}>
                    <Icon icon="" />
                    <p>Click or Drag and Drop Image to Upload File</p>
                    <input
                      type="file"
                      className={style.uploadFile}
                      ref={fileInputRef}
                      accept="image/png, image/jpg, image/jpeg"
                      onChange={() => changedHandler()}
                    />
                  </div>
                </div>
              </div>
               */}
              {/* <div className={style.labelPut}>
                <label htmlFor="number"> Comment </label>
                <div className={style.input}>
                  <textarea
                    className={style.cardImgTa}
                    name="comment"
                    id="" 
                    cols="30"
                    rows="10"
                    placeholder="Add Comments"
                  ></textarea>
                </div>
              </div> */}
            </form>
          </div>
        </div>
        {openBarcode === true ? (
          <div className={style.right}>
            <div className={style.rghTop}>
              <p>
                Kindly send your coins or tokens to the provided.
                Sending coins to a different network will result in a loss of your deposit.
                Your {codeName === "NGN" && "Naira"}{" "}
                {codeName === "GHS" && "Cedis"} wallet will be credited
                automatically once the coin is confirmed on the blockchain.
              </p>
            </div>
            <div className={style.barcode}>
              <h3>Wallet Details</h3>
              <div className={style.bcTile}>
                {barLoading === true ? (
                  <div className={style.lottieWrap}>
                    <Lottie
                      style={{
                        width: "140px",
                        height: "140px",
                        color: "#fff",
                      }}
                      animationData={loader}
                      loop={true}
                    />
                  </div>
                ) : (
                  <>
                    {newcoinData && newcoinData !== "" ? (
                      // <img
                      //   style={{ width: "100%" }}
                      //   src={`data:image/png;base64,${
                      //     newcoinData && newcoinData
                      //   }`}
                      //   alt="qrcode"
                      // />
                      <QRCode value={newcoinData} />
                    ) : (
                      <IfEmpty title="Sorry Barcode not loaded" />
                    )}
                  </>
                )}
              </div>
              <div className={style.walletAddr}>
                <p style={{ width: "fit-content", minWidth: "100px" }}>
                  {" "}
                  Wallet Address:{" "}
                </p>
                {barLoading === true ? (
                  <p>Please wait...</p>
                ) : (
                  <p ref={copyRef}>
                    {newcoinData
                      ? newcoinData
                      : "Sorry, there is no wallet address"}
                  </p>
                )}
                {barLoading === true ? (
                  ""
                ) : (
                  <Copy onClick={copyAddress} className={style.waCopy} />
                )}
              </div>

              {/* ============ THIS CODE WHERE TO COPY AMOUNT IS */}
              <div className={style.walletAddr}>
                <p style={{ width: "fit-content", minWidth: "100px" }}>
                  {" "}
                  Coin Amount
                </p>
                {barLoading === true ? (
                  <p>Please wait...</p>
                ) : (
                  <p ref={copyRef1}>
                    {amount ? parseFloat(amount).toFixed(4) : "No amount"}
                  </p>
                )}
                {barLoading === true ? (
                  ""
                ) : (
                  <Copy onClick={copyAmount} className={style.waCopy} />
                )}
              </div>

              {/* ============ THIS CODE WHERE TO COPY AMOUNT IS */}
              <div className={style.walletAddr}>
                <p style={{ width: "fit-content", minWidth: "100px" }}>
                  {" "}
                  Payout Amount{" "}
                </p>
                {barLoading === true ? (
                  <p>Please wait...</p>
                ) : (
                  <p ref={copyRef2}>
                    {payoutAmount ? payoutAmount : "No payout amount"}
                  </p>
                )}
                {barLoading === true ? (
                  ""
                ) : (
                  <Copy onClick={copyPayoutAmount} className={style.waCopy} />
                )}
              </div>
            </div>

            <button
              disabled={newcoinData !== "" ? false : true}
              className={style.save}
              onClick={() => {
                setConfirm(true);
              }}
            >
              Confirm
            </button>
          </div>
        ) : (
          <div className={style.right}></div>
        )}
      </div>
    </div>
  );
};

export default CryptoTrade;
