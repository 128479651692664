// import { WalletSearch } from 'iconsax-react'
import React from 'react'
import { useParams } from 'react-router-dom'
import Bills from '../bills/Bills'
import Calculator from '../calculator/Calculator'
// import CryptoCalc from '../calculator/CryptoCalc'
import CryptoTrade from '../crypto/CryptoTrade'
import Main from '../main/Main'
import Navbar from '../navbar/Navbar'
import PersonalInfo from '../personlInfo/PersonalInfo'
import Referrals from '../referrals/Referrals'
import Settings from '../settings/Settings'
import Trade from '../trade/Trade'
import TradeDetails from '../trade/TradeDetails'
import Transaction from '../transactions/Transaction'
import Wallets from '../wallet/Wallets'
import style from './right.module.css'
import FundWalletPage from '../fundWallet/fundWallet'
import ExchangePage from '../exchange/Exchange'

const Right = ({ links, newRoute, setNewRoute, verifiedKYC, dynTitle, setDynTitle, setVerifiedKYC, openSidebar, setOpenSidebar, calcRoute, setCalcRoute }) => {
  
  const param = useParams();
  const paramName = param.cardname;

  return (
    <div className={style.right}>
      <div className={style.top}>
        <Navbar
          links={links}
          newRoute={newRoute}
          setNewRoute={setNewRoute}
          dynTitle={dynTitle}
          setDynTitle={setDynTitle}
          openSidebar={openSidebar}
          setOpenSidebar={setOpenSidebar}
        />
      </div>
      <div className={style.bottom}>
        {window.location.pathname === "/dashboard" && (
          <Main
            newRoute={newRoute}
            verifiedKYC={verifiedKYC}
            setNewRoute={setNewRoute}
          />
        )}
        {window.location.pathname === "/trade" && (
          <Trade verifiedKYC={verifiedKYC} />
        )}
        {window.location.pathname === `/trade/${paramName}` && (
          <TradeDetails verifiedKYC={verifiedKYC} />
        )}

        {/* ======================= EVERYTHING UNDER CRYPTO SECTION ========================== */}
        {window.location.pathname === "/crypto" && (
          <CryptoTrade verifiedKYC={verifiedKYC} />
        )}

        {/* ========== EVERYTHING UNDER FUND WALLET SECTION =============== */}
        {window.location.pathname === "/fund-wallet" && (
          <FundWalletPage verifiedKYC={verifiedKYC} />
        )}

          {/* ========== EVERYTHING UNDER EXCHANGE SECTION =============== */}
          {window.location.pathname === "/exchange" && (
          <ExchangePage verifiedKYC={verifiedKYC} />
        )}

        {/* ======================= EVERYTHING UNDER BILL PAYMENT SECTION ========================== */}
        {window.location.pathname === "/bills" && (
          <Bills verifiedKYC={verifiedKYC} />
        )}
        {window.location.pathname === "/bills/cable" && (
          <Bills verifiedKYC={verifiedKYC} />
        )}
        {window.location.pathname === "/bills/airtime" && (
          <Bills verifiedKYC={verifiedKYC} />
        )}
        {window.location.pathname === "/bills/data" && (
          <Bills verifiedKYC={verifiedKYC} />
        )}
        {window.location.pathname === "/bills/electricity" && (
          <Bills verifiedKYC={verifiedKYC} />
        )}

        {window.location.pathname === "/wallets" && (
          <Wallets verifiedKYC={verifiedKYC} />
        )}
        {window.location.pathname === "/transactions" && (
          <Transaction verifiedKYC={verifiedKYC} />
        )}

        {window.location.pathname === "/calculator" && (
          <Calculator
            calcRoute={calcRoute}
            setCalcRoute={setCalcRoute}
            verifiedKYC={verifiedKYC}
          />
        )}
        {window.location.pathname === "/referrals" && (
          <Referrals verifiedKYC={verifiedKYC} />
        )}

        {/* ======================= EVERYTHING UNDER DASHBOARD SECTION ========================== */}
        {window.location.pathname === "/dashboard/personal-info" && (
          <PersonalInfo
            setVerifiedKYC={setVerifiedKYC}
            verifiedKYC={verifiedKYC}
          />
        )}
        {window.location.pathname === "/dashboard/account-details" && (
          <PersonalInfo
            setVerifiedKYC={setVerifiedKYC}
            verifiedKYC={verifiedKYC}
          />
        )}
        {window.location.pathname === "/dashboard/transaction-pin" && (
          <PersonalInfo
            setVerifiedKYC={setVerifiedKYC}
            verifiedKYC={verifiedKYC}
          />
        )}

        {window.location.pathname === "/settings" && (
          <Settings
            newRoute={newRoute}
            setNewRoute={setNewRoute}
            verifiedKYC={verifiedKYC}
            dynTitle={dynTitle}
            setDynTitle={setDynTitle}
          />
        )}
      </div>
    </div>
  );
}

export default Right 