import React, { useEffect, useState } from 'react';
import { setHeader } from '../../store/settings/settingsSlice';
import { useDispatch, useSelector } from "react-redux";
import style from "./fund-wallet.module.css";
import nigeria_icon from '../../assets/images/nigeria.svg'
import ng_icon from '../../assets/images/ng.svg'
import ghana_icon from '../../assets/images/ghanaflag.svg'
import arrow_right from '../../assets/images/arrow-right.svg'
import usd_icon from '../../assets/images/usd.svg'
import info_icon from '../../assets/images/info_icon.svg'
import walletArrowBg from '../../assets/images/walletArrow.svg'
import spin_circle from '../../assets/images/Loader anim.png'
import copy_icon from '../../assets/images/copy.svg'
import successIcon from '../../assets/images/success-icon.svg'
import {  Eye,EyeSlash} from "iconsax-react";
import { useNavigate } from 'react-router-dom';
import Modal from '../modal/Modal';
import Lottie from "lottie-react";
import loader from "../../assets/lottie/loader.json";
import BVN from '../settings/BVN';
import { resetHasVerifiedBvn } from '../../store/KYC/kycSlice';
import { createVirtualAccount, fetchVirtualAccount, fetchVirtualBanks, fetchWithdrawalFees, InitiateDepositAccount } from '../../store/wallet/walletActions';
import cogoToast from "cogo-toast";
import { clearDepositAccount } from '../../store/wallet/walletSlice';


const FundWalletPage = () => {

  const dispatch = useDispatch();
  const [location, setLocation] = useState("NGN");
  const [visible, setVisible] = useState(true);
  const navigate  = useNavigate()
  //   const [currNo, setCurrNo] = useState(0);
  const walletData = useSelector((state) => state.wallet.data);
  const myLevel = useSelector((state) => state.userKyc.kycStats.userkyc)
  const hasVerifiedBvn = useSelector((state) => state.userKyc.hasVerifiedBvn);
  const virtualAccount = useSelector((state) => state.wallet.virtualAccount);
  const banks = useSelector((state) => state.wallet.banks);
  const depositAccount = useSelector((state) => state.wallet.depositAccount);
  const fees = useSelector((state) => state.wallet.fees);
  const [isOpen, setIsOpen] = useState(false);
  const [isBVNModal, setBVNModal] = useState(false);
  const [isInitiateDepositModal, setInitiateDepositModal] = useState(false);
  const [isAcctGeneratedModal, setAcctGeneratedModal] = useState(false);
  const [isDirectDepositModal, setDirectDepositModal] = useState(false);
  const [isDepositModal, setDepositModal] = useState(false);
  const [isInitiated, setIsInitiated] = useState(false);
  const [amount, setAmount] = useState("");
  const [initiateLoader, setInitiateLoader] = useState(false);
//   const [btnLoader, setBtnLoader] = useState(true)

  const [selectedBankCode, setSelectedBankCode] = useState(null);

    const handleSelectBank = (code) => {
        setSelectedBankCode(code);
    };

    const handleInputChange = (e, setState, validationRegex = /[^0-9.]/g) => {
        // Allow only characters matching the validation regex
        const validValue = e.target.value.replace(validationRegex, "");
        if (validValue.split(".").length > 2) return; // Prevent more than one decimal point
        setState(validValue);
    };

    const handleUpgrade = () =>{
        // close the select deposit type modal
        setDepositModal(false)
        // open the generate account modal
        setBVNModal(true)
    }

    const handleGenerateAcct = () =>{
        setDepositModal(false)
        setIsOpen(true)
        dispatch(createVirtualAccount({
            bankCode: selectedBankCode,
        })).then((res)=>{
            if(res?.type === "createVirtualAccount/fulfilled"){
                setIsOpen(false)
                setAcctGeneratedModal(true)
                if(location === "NGN"){
                    dispatch(fetchVirtualAccount({
                        currency: 1
                    }))   
                }
            }
            else if(res?.type === "createVirtualAccount/rejected"){
                setIsOpen(false)
            }
        })

    }
    

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(
          () => {
             cogoToast.success("Copied to Clipboard", {
                position: "top-right"
             })
          },
          (err) => {
            console.error('Failed to copy: ', err);
          }
        );
      };

      const handleInitiation = () =>{
        const wallet = walletData.fiatWallets.find(wallet => location.includes(wallet.Currency.code));

        setInitiateLoader(true)
        dispatch(InitiateDepositAccount({
            currencyId: wallet.Currency.id,
            amount: parseFloat(amount)
        })).then((res)=>{
            if(res?.type === "InitiateDepositAccount/fulfilled"){
                setInitiateLoader(false)
                setIsInitiated(true)
            }
            else if(res?.type === "InitiateDepositAccount/rejected"){
                setInitiateLoader(false)
            }
        })
      }

      const confirmInitiateDeposit = () =>{
        setInitiateDepositModal(false)
        setIsInitiated(false)
        setAmount("")
        setDirectDepositModal(true)
        dispatch(clearDepositAccount())
      }

    useEffect(()=>{
        if(hasVerifiedBvn){
            setBVNModal(false)
              setTimeout(() => {
                dispatch(resetHasVerifiedBvn());
            }, 3000);
        }
    },[hasVerifiedBvn, dispatch])

    useEffect(()=>{
        if(location === "NGN"){
            dispatch(fetchVirtualAccount({
                currency: 1
            }))   
        }
    },[dispatch, location])

     // handle deposit fees
      useEffect(()=>{
        if(location === "NGN"){
          dispatch(fetchWithdrawalFees({
            currency: 'naira'
          }))
        }
        if(location === "GHS"){
          dispatch(fetchWithdrawalFees({
            currency: 'ghana'
          }))
        }
      },[dispatch, location])
    
  
    useEffect(() => {
        dispatch(setHeader("Fund Wallet"));
        dispatch(fetchVirtualBanks())
     }, [dispatch]);

    return ( 
        <>

    {/* BVN MODAL */} 
      <Modal canClose modalOpen={isBVNModal} setModalOpen={setBVNModal}>
        <BVN />
     </Modal>

      {/* ===== INITIATE DEPOSIT MODAL */}
      <Modal title='Fund Wallet with Direct Deposit'  minWidth='40%' canClose modalOpen={isInitiateDepositModal} setModalOpen={setInitiateDepositModal}>

        {!isInitiated
        ?
        <div style={{marginTop: '25px'}}>

             <div className={style.labelPut}>
                <label htmlFor="wallet">Payout Wallet</label>
                 <div className={style.payoutDiv}>
                    <img src={ghana_icon} alt="ghana icon" width={24} height={24} />
                    <p>GHS</p>
                </div>
             </div>

              <div className={style.labelPut}>
                <label htmlFor="amount">Amount<span style={{color:'#FF0000'}}>*</span></label>
                <div className={style.input}>
                <input
                    type="text"
                    name="amount"
                    value={amount}
                    onChange={(e) => handleInputChange(e, setAmount)}
                    placeholder="Enter Amount"
                />
                </div>

                <div className={style.info_div}>
                    <img src={info_icon} alt="info icon" />
                    <p style={{margin: '0px', color: '#1A1A1A'}}> A GHS {fees && fees.ghana_deposit_fee ? fees?.ghana_deposit_fee?.value?.data: 0} charge applies to this deposit.</p>
                </div>
              </div>

        {initiateLoader === true ? (
           <div className={style.lottieWrap}>
            <Lottie
              style={{
                width: "70px",
                height: "70px",
                color: "#fff",
                padding: "0px",
                margin: "0px",
              }}
              animationData={loader}
              loop={true}
            />
           </div>
            ) : (
            <button onClick={handleInitiation} style={{marginTop: '25px'}} className={style.save}>
                Proceed
                </button>
                )
            }

        </div>
        :
        <div style={{marginTop: '25px'}}>

            <div style={{maxWidth: '350px', margin: 'auto'}}>
                <p style={{color: '#9E9E9E', fontSize: 14, fontWeight: 500}}>Kindly make a deposit into the provided account. Use the deposit code as Narration when making payment</p>
            </div>

            <div style={{marginTop: '10px'}}>
                <label>Pay</label>
                 <div style={{ background: '#F8F4FB', padding: '16px 16px', borderRadius: '12px', marginTop: '10px', color: '#1A1A1A', fontWeight: 600, fontSize: 14, minHeight: '50px'}}>
                    {amount} GHS
                </div> 
            </div>

            <div style={{marginTop: '20px'}}>
                <label>To</label>
                 <div style={{ background: '#F8F4FB', padding: '16px 16px', display: 'flex', justifyContent : 'space-between', alignItems: 'center', borderRadius: '12px', marginTop: '10px',}}>
                    <div>
                        <h6 style={{color: '#1A1A1A', fontSize: '14px', fontWeight: 600}}>{depositAccount? depositAccount.bankDetails.account_name : ''}</h6>
                        <h6 style={{color: '#1A1A1A', fontSize: '14px', fontWeight: 600,marginTop: '2px'}}>{depositAccount? depositAccount.bankDetails.account_number : ''}</h6>
                        <h5 style={{color: '#ADADAD', fontSize: '13px', fontWeight: 500,marginTop: '2px'}}>{depositAccount? depositAccount.bankDetails.bank_name : ''}</h5>
                    </div>
                    <div>
                        <img onClick={() => copyToClipboard(`${depositAccount? depositAccount.bankDetails.account_number : ''}`)} style={{cursor: 'pointer'}} src={copy_icon} alt="copy icon" />
                    </div>
                </div> 
                <div className={style.info_div}>
                    <img src={info_icon} alt="info icon" />
                    <p style={{margin: '0px', color: '#40196D'}}>A GHS {fees && fees.ghana_deposit_fee ? fees?.ghana_deposit_fee?.value?.data: 0} charge applies to this deposit.</p>
                </div>
            </div>

            <div style={{marginTop: '20px'}}>
                <label>Deposit Code</label>
                 <div style={{ background: '#F8F4FB', padding: '16px 16px', display: 'flex', justifyContent : 'space-between', alignItems: 'center', borderRadius: '12px', marginTop: '10px',}}>
                    <div>
                        <h6 style={{color: '#1A1A1A', fontSize: '14px', fontWeight: 600}}>{depositAccount? depositAccount.transactionRef : 'ASTRO' }</h6>
                    </div>
                    <div>
                        <img onClick={() => copyToClipboard(`${depositAccount? depositAccount.transactionRef : 'ASTRO'}`)} style={{cursor: 'pointer'}} src={copy_icon} alt="copy icon" />
                    </div>
                </div> 
                <div className={style.info_div}>
                    <img src={info_icon} alt="info icon" />
                    <p style={{margin: '0px', color: '#40196D'}}>Use the deposit code as Narration when making payment</p>
                </div>
            </div>
            
            <div style={{marginTop: '25px', display: 'flex', justifyContent: 'center'}}>
                <p style={{color: '#5F177F', fontSize: '12px',marginBottom: '0px'}}>I have made a payment of {amount}GHS into the account above.</p>
            </div>
            <button onClick={confirmInitiateDeposit} style={{marginTop: '10px'}} className={style.save}>
               Confirm
            </button>

        </div>
        }
     </Modal>

      {/* ========= ==  SELECT DEPOSIT BANK MODAL */}
      <Modal
        canClose={true}
        modalOpen={isDepositModal}
        setModalOpen={setDepositModal}
        title='Select Deposit Bank'
        minWidth='35%'
      >

       {
        myLevel && myLevel.kyc_setting_id < 2
        ?
        (
        <div style={{marginTop: '30px'}}>
            <p style={{color: '#B6B6B6', maxWidth:'300px', margin: 'auto', marginTop: '15px'}}>
            To open a deposit account, upgrade to level 2 verification for swift verification and additional priviledges
                </p>
         </div>
            )
           :
           ( 
           banks.map((bank, index) => (
            <div
            key={bank.id}
            onClick={() => handleSelectBank(bank.bankCode)}
            style={{ marginTop: index === 0 ? "30px" : "10px", pointerEvents: `${bank.isActive ? 'auto' : 'none'}` }}
            className={`${style.depositBankCard} ${selectedBankCode === bank.bankCode ? `${style.active}` : ""}`}
            >
            <h6 className={style.bank}>{bank.bankName} {!bank.isActive&&<span style={{color: '#909090', fontSize: '12px'}}>(Not available)</span>}</h6>
            {/* <h5 className={style.bankfee}>Fee: {bank.fee}</h5> */}
            </div>
           ))
         )
       }
       
    
        <div style={{marginTop: '20px'}}>
            {
            myLevel && myLevel.kyc_setting_id < 2
                ?
             <button onClick={()=>handleUpgrade()} className={style.save} >
                Upgrade to Level 2
            </button>
            :
             <button onClick={() =>handleGenerateAcct()} disabled={selectedBankCode === null || isOpen} className={style.save}>
                Generate
                </button>
            }
        </div>
       

     </Modal>

       {/* =========================== THE LOADING MODAL TO GENERATE DEPOSIT ACCOUNT  */}
      <Modal
        canClose={false}
        modalOpen={isOpen}
        setModalOpen={setIsOpen}
      >
        <div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '25px'}}>
                <img src={spin_circle} alt="spin circle" className={style.animate_spin} />
            </div>

            <div>
                <h4 style={{color: '#40196D', fontWeight: '600', marginTop: '25px'}}>Generating Deposit Account</h4>
             
                <p style={{color: '#B6B6B6', maxWidth:'300px', margin: 'auto', marginTop: '15px'}}>
                  Please wait...<br/>
                    Your NGN wallet deposit account
                    is being generated
                </p>
               
            </div>
        </div>

        </Modal>

        {/* ======== THE MODAL TO STATE THAT DEPOSIT ACCOUNT HAS BEEN GENERATED */}
        <Modal
        canClose={true}
        modalOpen={isAcctGeneratedModal}
        setModalOpen={setAcctGeneratedModal}
      >
        <div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '25px'}}>
                <img src={successIcon} alt="spin circle" />
            </div>

            <div>
                <h4 style={{color: '#40196D', fontWeight: '600', marginTop: '25px'}}>Deposit Account Generated</h4>
            
                <p style={{color: '#B6B6B6', maxWidth:'300px', margin: 'auto', marginTop: '15px'}}>
                  Your NGN wallet deposit account
                  has been generated successfully
                </p>
            </div>

            <button onClick={() => setAcctGeneratedModal(false)} style={{marginTop: '20px'}} className={style.save}>
               Continue
            </button>

        </div>

        </Modal>

           {/* ======== THE MODAL TO STATE THAT DIRECT DEPOSIT HAS BEEN INITIATED */}
           <Modal
        canClose={true}
        modalOpen={isDirectDepositModal}
        setModalOpen={setDirectDepositModal}
      >
        <div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '25px'}}>
                <img src={successIcon} alt="spin circle" />
            </div>

            <div>
                <h4 style={{color: '#40196D', fontWeight: '600', marginTop: '25px'}}>Direct Deposit Initiated</h4>
            
                <p style={{color: '#B6B6B6', maxWidth:'300px', margin: 'auto', marginTop: '15px'}}>
                Your direct deposit has been initiated and is being processed. Your GHS wallet will be credited immediately deposit is confirmed
                </p>
            </div>

            <button onClick={() => setDirectDepositModal(false)} style={{marginTop: '20px'}} className={style.save}>
               Close
            </button>

        </div>

        </Modal>

        <div
            className={style.cashTabs}
        >
            <ul>
            <li
                onClick={() => {
                setLocation("NGN");
                }}
                style={{
                borderBottom:
                    location === "NGN"
                    ? "3px #40196D solid"
                    : "0px #90909039 solid",
                    
                }}
            >
                <div>
                    <img src={nigeria_icon} alt="nigeria icon" 
                    style={{ width: "22px", height: '22px', borderRadius: '50%', opacity: `${location === "NGN" ? '100%': '60%'}` }} />
                <p
                style={{
                    color:
                    location === "NGN" ?
                    "#40196D"
                    :
                    "#C1C1C1"
                }}
                >NGN WALLET</p>
                </div>
            </li>
            <li
                onClick={() => {
                    setLocation("GHS");
                }}
                style={{
                borderBottom:
                    location === "GHS"
                    ? "3px #40196D solid"
                    : "0px #90909039 solid",
                }}
            >
            <div>
                    <img src={ghana_icon} alt="ghana icon" 
                    style={{ width: "22px", height: '22px', borderRadius: '50%', opacity: `${location === "GHS" ? '100%': '60%'}` }} />
                <p
                style={{
                    color:
                    location === "GHS" ?
                    "#40196D"
                    :
                    "#C1C1C1"
                }}
                >GHS WALLET</p>
                </div>
            </li>
            <li
                onClick={() => {
                    setLocation("USD");
                }}
                style={{
                borderBottom:
                    location === "USD"
                    ? "3px #40196D solid"
                    : "0px #90909039 solid",
                }}
            >
            <div>
                <img src={usd_icon} alt="usd icon"
                style={{ width: "22px", height: '22px', borderRadius: '50%',opacity: `${location === "USD" ? '100%': '60%'}` }} />
                <p
                style={{
                    color:
                    location === "USD" ?
                    "#40196D"
                    :
                    "#C1C1C1"
                }}
                >USD WALLET</p>
                </div>
            </li>
            </ul>
        </div>

        {/* ------ NGN WALLET SECTION */}
       {
        location === "NGN" && (
        <div className={style.walletDiv}>
            <div className={style.walletCard}>

                <div>
                    <p className={style.walletTitle}>
                    {walletData.fiatWallets && walletData.fiatWallets.length
                        ? "WALLET BALANCE"
                        : "ADD A WALLET "}
                    </p>

                    {walletData.fiatWallets && walletData.fiatWallets.length ? (
                     <div className={style.balance}>
                        <p>
                        {visible === true
                            ? `${
                                walletData.fiatWallets
                                ? (
                                    walletData.fiatWallets[0]
                                        ? parseFloat(walletData.fiatWallets[0].balance).toLocaleString()
                                        : ""
                                    )
                                : "0.000"
                            }`
                            : "XXXXXXXXX"}
                        </p>
                        {visible === true ? (
                        <EyeSlash
                            onClick={() => {
                            setVisible(!visible);
                            }}
                            className={style.walEye}
                            color="#fff"
                            variant="Bulk"
                            style={{cursor: 'pointer'}}
                        />
                        ) : (
                        <Eye
                            onClick={() => {
                            setVisible(!visible);
                            }}
                            className={style.walEye}
                            color="#fff"
                            variant="Bulk"
                            style={{cursor: 'pointer'}}

                        />
                        )}
                     </div>
                     ) : (
                        <p style={{marginTop: '8px', color: '#fff'}} className={style.walletTitle}>You have no wallets</p>
                     )}
                </div>

               
                 {walletData.fiatWallets && walletData.fiatWallets.length ? (
                    <div className={style.countryDiv}>
                    <img src={ng_icon} alt="nigeria icon" 
                    />
                    <p className={style.currencyText}>NGN</p>
                  </div>  
                 ) : "" }

                <div className={style.walletArrowBg}>
                    <img src={walletArrowBg} alt="wallet arrow" />
                </div>   
            </div>

            <div className={style.directBankDiv}>
                <div>
                    {
                        virtualAccount
                        ?
                        (
                         <div>
                            <h6 className={style.title}>{virtualAccount?.account_name || ""}</h6>
                            <p className={style.subtitle}>{virtualAccount?.account_no || ""}</p>
                            <p className={style.subtitle} style={{marginTop: '4px'}}>{virtualAccount?.bank_name || ""}</p>
    
                            <div className={style.info_div}>
                            <img src={info_icon} alt="info icon" />
                            <p>A {fees && fees.naira_deposit_fee ? fees?.naira_deposit_fee?.value?.data: 0}% fee applies to deposits, capped at ₦1,000</p>
                           </div>
    
                        </div>
                        )
                        :
                        (   
                            <>
                            <h6 className={style.title}>Direct Bank Deposit</h6>
                            <p className={style.subtitle}>No Naira Deposit Account Yet Generate your deposit account.</p>
                            </>
                        )
                    }
                   
            
                </div>
                <div className={style.ctaDiv}>
                    {
                        virtualAccount ?
                        <button 
                        onClick={() => copyToClipboard(virtualAccount?.account_no || "")}
                        className={style.ctaBtn}>Copy to Deposit</button>
                            :
                        <button onClick={() => setDepositModal(true)} className={style.ctaBtn}>Click to Generate</button>
                        }
                    <img src={arrow_right} alt='arrow right' />
                </div>
            </div>

            <div className={style.cyptoBankDiv}>
                <div>
                    <h6 className={style.title}>Crypto Deposit</h6>
                    <p className={style.subtitle}>Fund your NGN Wallet 
                    using crypto.</p>
                </div>
                <div className={style.ctaDiv}>
                    <button onClick={() => navigate('/crypto')} className={style.ctaBtn}>Fund Wallet With Crypto</button>
                    <img src={arrow_right} alt='arrow right' />
                </div>
            </div>
        </div>
        )}

        {/* --- GHS WALLET SECTION */}
        {
        location === "GHS" && (
        <div className={style.walletDiv}>
            <div className={style.walletCard}>

                <div>
                    <p className={style.walletTitle}>
                    {walletData.fiatWallets && walletData.fiatWallets.length
                        ? "WALLET BALANCE"
                        : "ADD A WALLET "}
                    </p>
                    {walletData.fiatWallets && walletData.fiatWallets.length ? (
                    <div className={style.balance}>
                        <p>
                        {visible === true
                            ? `${
                                walletData.fiatWallets
                                ? (
                                    walletData.fiatWallets[1]
                                        ? parseFloat(walletData.fiatWallets[1].balance).toLocaleString()
                                        : "0.00"
                                    )
                                : "0.00"
                            }`
                            : "XXXXXXXXX"}
                        </p>
                        {visible === true ? (
                        <EyeSlash
                            onClick={() => {
                            setVisible(!visible);
                            }}
                            className={style.walEye}
                            color="#fff"
                            variant="Bulk"
                            style={{cursor: 'pointer'}}
                        />
                        ) : (
                        <Eye
                            onClick={() => {
                            setVisible(!visible);
                            }}
                            className={style.walEye}
                            color="#fff"
                            variant="Bulk"
                            style={{cursor: 'pointer'}}

                        />
                        )}
                     </div>
                    ) : (
                        <p style={{marginTop: '8px', color: '#fff'}} className={style.walletTitle}>You have no wallets</p>
                     )}
                </div>

                {walletData.fiatWallets && walletData.fiatWallets.length ? (
                <div className={style.countryDiv}>
                    <img src={ghana_icon} alt="ghana icon" 
                    />
                    <p className={style.currencyText}>GHS</p>
                </div>  
                 ) : "" }

                <div className={style.walletArrowBg}>
                    <img src={walletArrowBg} alt="wallet arrow" />
                </div>   
            </div>

            <div className={style.directBankDiv}>
                <div>
                    <h6 className={style.title}>Direct Bank Deposit</h6>
                    <p className={style.subtitle}>Fund your GHS Wallet using direct deposit to bank.</p>

                 
                </div>
                <div className={style.ctaDiv}>
                    <button onClick={() => setInitiateDepositModal(true)} className={style.ctaBtn}>Initiate Deposit</button>
                    <img src={arrow_right} alt='arrow right' />
                </div>
            </div>

            <div className={style.cyptoBankDiv}>
                <div>
                    <h6 className={style.title}>Crypto Deposit</h6>
                    <p className={style.subtitle}>Fund your GHS Wallet 
                    using crypto.</p>
                </div>
                <div className={style.ctaDiv}>
                    <button onClick={() => navigate('/crypto')} className={style.ctaBtn}>Fund Wallet With Crypto</button>
                    <img src={arrow_right} alt='arrow right' />
                </div>
            </div>
        </div>
         )}

         {/* --- USD WALLET SECTION */}
         {
        location === "USD" && (
        <div className={style.walletDiv}>
            <div className={style.walletCard}>

                <div>
                  <p className={style.walletTitle}>
                    {walletData.fiatWallets && walletData.fiatWallets.length
                        ? "WALLET BALANCE"
                        : "ADD A WALLET "}
                    </p>
                    {walletData.fiatWallets && walletData.fiatWallets.length ? (
                    <div className={style.balance}>
                        <p>
                        {visible === true
                            ? `${
                                walletData.fiatWallets
                                ? (
                                    walletData.fiatWallets[2]
                                        ? parseFloat(walletData.fiatWallets[2].balance).toLocaleString()
                                        : "0.00"
                                    )
                                : "0.00"
                            }`
                            : "XXXXXXXXX"}
                        </p>
                        {visible === true ? (
                        <EyeSlash
                            onClick={() => {
                            setVisible(!visible);
                            }}
                            className={style.walEye}
                            color="#fff"
                            variant="Bulk"
                            style={{cursor: 'pointer'}}
                        />
                        ) : (
                        <Eye
                            onClick={() => {
                            setVisible(!visible);
                            }}
                            className={style.walEye}
                            color="#fff"
                            variant="Bulk"
                            style={{cursor: 'pointer'}}

                        />
                        )}
                     </div>
                       ) : (
                        <p style={{marginTop: '8px', color: '#fff'}} className={style.walletTitle}>You have no wallets</p>
                     )}
                </div>
            
                {walletData.fiatWallets && walletData.fiatWallets.length ? (
                <div className={style.countryDiv}>
                    <img src={usd_icon} alt="usd icon" 
                    style={{ width: "24px", height: '24px', borderRadius: '50%' }}
                    />
                    <p className={style.currencyText}>USD</p>
                </div>  
                  ) : "" }

                <div className={style.walletArrowBg}>
                    <img src={walletArrowBg} alt="wallet arrow" />
                </div>   
            </div>

            <div className={style.directBankDiv}>
                <div>
                    <h6 className={style.title_disabled}>Direct Bank Deposit</h6>
                    <p className={style.subtitle}>No Dollar Deposit Account Yet Generate your deposit account.</p>

                 
                </div>
                <div className={style.ctaDiv}>
                    <button className={style.ctaBtn_disabled}>Not available</button>
                </div>
            </div>

            <div className={style.cyptoBankDiv}>
                <div>
                    <h6 className={style.title}>Crypto Deposit</h6>
                    <p className={style.subtitle}>Fund your USD Wallet 
                    using crypto.</p>
                </div>
                <div className={style.ctaDiv}>
                    <button onClick={() => navigate('/crypto')} className={style.ctaBtn}>Fund Wallet With Crypto</button>
                    <img src={arrow_right} alt='arrow right' />
                </div>
            </div>
        </div>
         )}

        </>
     );
}
 
export default FundWalletPage;