import React, { useEffect } from "react";
import style from "./main.module.css";
import { Icon } from "@iconify/react";
import naija from "../../assets/images/naija.svg";
import vCard from "../../assets/images/v-card.svg";
import graph from "../../assets/images/graph.svg";
import wallet_icon from "../../assets/images/wallet.svg";
import { ArrowRotateRight, BuyCrypto, CardPos, CardReceive, Receive, Trade } from "iconsax-react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import smChrt from "../../assets/images/sm-chrt.svg";
import { Link, Navigate, useNavigate } from "react-router-dom";
import WalletCard from "../wallet/WalletCard";
import IfEmpty from "../ifEmpty/IfEmpty";
import empty1 from "../../assets/images/empty1.svg";
import { useDispatch, useSelector } from "react-redux";
import { getRecentTransactions } from "../../store/transactions/transActions";
import Lottie from "lottie-react";
import loader from "../../assets/lottie/loader.json";
import { useState } from "react";
import Modal from "../modal/Modal";
import PinInput from "react-pin-input";
import { setPin } from "../../store/settings/settingsActions";
import { getUser, getUserById } from "../../store/auth/authActions";
import { setHeader, setProfileImage } from "../../store/settings/settingsSlice";
import moment from 'moment'
import { getFiatCurrencies, getWalletBalance } from "../../store/wallet/walletActions";
import nig from '../../assets/images/Flag-Nigeria.webp'
import { getKycStatusById } from "../../store/KYC/kycAction";
const transaction = [];


const actions = [
  {
    id: 1,
    route: "/trade",
    title: "Trade",
    icon: <CardPos size="32" color="#ffffff" variant="Outline" />,
  },
  // {
  //   id: 2,
  //   route: "/wallets",
  //   title: "Send",
  //   icon: <Icon className={style.actionCons} icon="bi:send" />,
  // },
  {
    id: 6,
    route: "/crypto",
    title: "Sell Crypto",
    icon: <BuyCrypto size="32" color="#ffffff" variant="Outline" />,
  },

  {
    id: 3,
    route: "/wallets",
    title: "Withdraw",
    icon: <Receive size="24" color="#ffffff" variant="Outline" />,
  },

  {
    id: 4,
    route: "/fund-wallet",
    title: "Fund Wallet",
    icon: <img src={wallet_icon} />,
  },
  // {
  //   id: 5,
  //   route: "/bills",
  //   title: "Bills",
  //   icon: <Icon className={style.actionCons} icon="ph:television-simple" />,
  // },
];

const Main = ({ verifiedKYC, setNewRoute }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  var moment = require("moment");

  const [loading, setLoading] = useState(false);
  const [totalTrades, setTotalTrades] = useState(0);
  const [totalTrans, setTotalTrans] = useState(0);
  const [currNo, setCurrNo] = useState(0);
  const [curCode, setCurCode] = useState("");
  const [isPin, setIsPin] = useState(false);
  const [pinNo, setPinNo] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false)
  const [openPinModal, setOpenPinModal] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true) 


  const userData = useSelector((state) => state.auth.user);
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const isLoading = useSelector((state) => state.trans.loading);
  const transactions = useSelector((state) => state.trans.recentData);
  const walletData = useSelector((state) => state.wallet.data);
  const saveLoad = useSelector((state) => state.settings.saveLoading);
  const totalTransactions = useSelector((state) => state.trans.recentData.totalNumberOfTransaction);
  const allTrades = useSelector((state) => state.trans.recentData.totalNumberOfTrade);
  const isPinSet = useSelector((state) => state.auth.pinData);
  const pinData = useSelector((state) => state.settings.isSetPin);
  


  const formatTagName = (value) => {
    let res;    
    switch (value) {
      case "Wallet withdrawer":
        res = "Wallet Withdrawal";
        break;
      case "Bank Account withdrawer":
        res = "Wallet Withdrawal";
        break;
      default:
        break;
    }
    return res;
  } 


function handleClick() {
  this.setState(prevState => ({
    isToggleOn: !prevState.isToggleOn
  }));
}




  const handleSetPin = () => {
    dispatch(setPin({ pinNo }))
  }

  useEffect(() => { 
    dispatch(setHeader("Dashboard"))
    dispatch(getFiatCurrencies());
        dispatch(getKycStatusById());
    // dispatch(getWalletBalance());
  },[])

  useEffect(() => {
    dispatch(getRecentTransactions());
  }, [isAuth]);

  useEffect(() => {
    if (isAuth === false) {
      navigate("/login");
    }
  }, [isAuth]);

  useEffect(() => {
    if (isLoading === true) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    if (isPinSet.isSetPin === true) {
      setIsPin(true)
    } else {
      setIsPin(false)
    }
    
    if (saveLoad === true) {
      setSaveLoader(true)
    } else {
      setSaveLoader(false)
    }

    if (isPinSet.isSetPin === true) {
      setOpenPinModal(false)
    } else {
      setOpenPinModal(true)
    }

  }, [isLoading, isPin, saveLoad, isPinSet]);

  useEffect(() => {
    if (userData.image_url !== null) {
      dispatch(setProfileImage(userData.image_url));
    }

    // dispatch(getUserById())

  }, []);

  useEffect(() => {
    setTotalTrades(allTrades);
    setTotalTrans(totalTransactions);
  }, [allTrades, totalTransactions]);

  useEffect(() => {
    if (pinNo !== "" && pinNo.length === 5) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [pinNo]);

  return (
    <div className={style.main}>
      <Modal modalOpen={openPinModal}>
        <div className={style.setYourPin}>
          <h2>Set Transaction Pin</h2>
          <p>
            Please set your transation PIN which will enable you to make
            transactions on Astro
          </p>
          <div className={style.inputs}>
            <PinInput
              length={5}
              initialValue=""
              secret={true}
              onChange={(value, index) => {
                setPinNo(value);
              }}
              type="numeric"
              inputMode="number"
              style={{ padding: "10px", margin: "0 auto 0 auto" }}
              inputStyle={{ border: "1px solid", borderColor: "#909090" }}
              inputFocusStyle={{ border: "2px solid", borderColor: "#40196D" }}
              onComplete={(value, index) => {
                setPinNo(value);
              }}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
          </div>
          {saveLoader === true ? (
            <div className={style.lottieWrap}>
              <Lottie
                style={{
                  width: "70px",
                  height: "70px",
                  color: "#fff",
                  padding: "0px",
                  margin: "0px",
                }}
                animationData={loader}
                loop={true}
              />
            </div>
          ) : (
            <button
              onClick={handleSetPin}
              disabled={isDisabled}
              className={style.save}
            >
              Set Transaction Pin
            </button>
          )}
        </div>
      </Modal>
      <div className={style.mainContainer}>
        <h4 className={style.welcome}>Welcome Back, {userData?.first_name}</h4>
        <div className={style.bottom}>
          <div className={style.left}>
            <WalletCard />

            <div className={style.quickActions}>
              {/* <h4 className={style.welcome}>Quick Actions</h4> */}
              <div className={style.qWrapper}>
                {actions &&
                  actions.map((action) => (
                    <div
                      key={action.id}
                      onClick={() => {
                        navigate(action.route);
                      }}
                      className={style.qAction}
                    >
                      <div className={style.qIconWrap}>{action.icon}</div>
                      <p className={style.title}>{action.title}</p>
                    </div>
                  ))}
              </div>
            </div>

            <div className={style.stats}>
              <h2>Statistics</h2>
            </div>
            <div className={style.moneyCards}>
              <div className={style.moneyCard}>
                <h2>{totalTrans > 0 ? totalTrans : 0}</h2>
                <p>Total Number of Transactions</p>
              </div>
              <div className={style.moneyCard}>
                <h2>{totalTrades > 0 ? totalTrades : 0}</h2>
                <p>Total Number of Trades</p>
              </div>
            </div>
          </div>

          <div className={style.right}>
            <div className={style.recentTransactions}>
              <h4 className={style.welcome}>Recent Transactions</h4>
              {loading === true ? (
                <div className={style.lottieWrap}>
                  <Lottie
                    style={{
                      width: "70px",
                      height: "70px",
                      color: "#fff",
                      padding: "0px",
                      margin: "0px",
                    }}
                    animationData={loader}
                    loop={true}
                  />
                </div>
              ) : (
                <div className={style.transaction}>
                  {transactions.recentTransaction ? (
                    <>
                      {transactions.recentTransaction.length > 0 ? (
                        transactions.recentTransaction.map((item) => (
                          <div key={item.id} className={style.transactions}>
                            <div className={style.oneSaction}>
                              <div className={style.triCon}>
                                <ArrowRotateRight className={style.triConOn} />
                              </div>
                              <div className={style.triTitleDate}>
                                <p>
                                  {item.tag && item.tag !== "Sell_Crypto" ? item.tag : ""}
                                  {item.GiftCard && item.GiftCard.name}
                                  {/* {item.PaymentOutwards
                                    ? item.PaymentOutwards[0].tag
                                    : ""} */}
                                  {item.PaymentOutwards
                                    ? formatTagName(item.PaymentOutwards[0].tag)
                                    : ""}
                                  {item.CryptoAccount 
                                    ?  `USD withdrawal to ${item.CryptoAccount.coin_type}`
                                    : ""}
                                  {item.CoinType 
                                    ?  `Sell Crypto ( ${item.CoinType.name } to ${item.CreditCurrency.code} )`
                                    : ""}
                                </p>
                                <span>
                                  {/* {item.created_at.slice(0, 10)},{" "}
                                  {item.created_at.slice(11, 16)}
                                  {item.created_at.slice(11, 13) > 11
                                    ? " PM"
                                    : " AM"} */}
                                  {item.created_at
                                    ? moment(item.created_at).format(
                                        "Do, MMM YYYY hh:mm:ss a"
                                      )
                                    : ""}
                                </span>
                              </div>

                              <div className={style.triAmountStatus}>
                                <p>
                                  {item.GiftCard &&
                                    new Intl.NumberFormat("NG", {
                                      style: "currency",
                                      currency: "USD",
                                    }).format(item.amount)}
                                  {item.CoinType &&
                                    new Intl.NumberFormat("NG", {
                                      style: "currency", 
                                      currency: "USD",
                                    }).format(item.amount)}
                                  {item.BankAccount && item.BankAccount.type === "ng-account" &&
                                    new Intl.NumberFormat("NG", {
                                      style: "currency",
                                      currency: "NGN",
                                    }).format(item.amount)}
                                  {item.BankAccount && item.BankAccount.type === "gh-account" &&
                                    new Intl.NumberFormat("NG", {
                                      style: "currency",
                                      currency: "GHS",
                                    }).format(item.amount)}
                                  {item.BankAccount && item.BankAccount.type === "gh-mobile" &&
                                    new Intl.NumberFormat("NG", {
                                      style: "currency",
                                      currency: "GHS",
                                    }).format(item.amount)}
                                  {item.CryptoAccount &&
                                    new Intl.NumberFormat("NG", {
                                      style: "currency",
                                      currency: "USD",
                                    }).format(item.amount)}
                                    {
                                      item.FiatCurrency &&
                                      `${
                                        walletData?.fiatWallets?.find(wallet => 
                                          item?.currency_id === wallet.Currency.id
                                      )?.Currency.code} ${item.amount}`
                                    }
                                </p>
                                {item.status === "DONE" && (
                                  <p className={style.success}>Successful</p>
                                )}
                                {item.status === "PROCESSING" && (
                                  <p className={style.pending}>Pending</p>
                                )}
                                {item.status === "SUBMITTED" && (
                                  <p className={style.pending}>Pending</p>
                                )}
                                {item.status === "FAILED" && (
                                  <p className={style.failed}>Declined</p>
                                )}
                                {item.status === "CANCELED" && (
                                  <p className={style.failed}>Declined</p>
                                )}
                              </div>
                            </div>{" "}
                          </div>
                        ))
                      ) : (
                        <IfEmpty
                          title="Sorry, you do not have any recent transactions"
                          buttonText="Proceed to Trade"
                          route="/trade"
                          image={empty1}
                        />
                      )}
                    </>
                  ) : (
                    <IfEmpty title="Oops! an error occurred" image={empty1} />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
