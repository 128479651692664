import React, { startTransition, useState } from "react";
import style from "./sidebar.module.css";
import logo from "../../assets/images/logo.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  Home2,
  Card,
  EmptyWallet,
  ArrowRotateRight,
  Setting2,
  ConvertCard,
  Calculator,
  Wallet3,
  Wallet,
  Bill,
  LogoutCurve,
  CloseSquare,
} from "iconsax-react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/auth/authSlice";
import { getDepositTransactions } from "../../store/transactions/transActions";
import { clearProfileImage } from "../../store/settings/settingsSlice";

const settLinks = [
  { id: 1, title: "Profile Info", route: "settings" },
  { id: 2, title: "Add Bank Account", route: "bank" },
  { id: 3, title: "Set Transaction Pin", route: "setPin" },
  { id: 4, title: "Change Transaction Pin", route: "changePin" },
];

const Sidebar = ({
  links,
  newRoute,
  setNewRoute,
  dynTitle,
  setDynTitle,
  openSidebar,
  setOpenSidebar,
}) => {
  const navigate = useNavigate();
  // const [route, setRoute] = useState("");
  const param = useParams();
  const paramName = param.cardname;
  const [openSettings, setOpenSettings] = useState(true);
  const [pinSet, setPinSet] = useState(false);

  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const isVerified = useSelector(state => state.auth.fullUser.is_verified)
  const isPin = useSelector((state) => state.auth.pinData.isSetPin);
  const isPinSet = useSelector((state) => state.settings.isSetPin);

  const handleSettLinks = (route) => {
    setNewRoute(route);
  };

  // useEffect(() => { 
  //   if (isVerified === false) {
  //     navigate('/email-verify')
  //   }
  // }, [isVerified])

  useEffect(() => {
    if (isAuth === false) {
      navigate("/signin");
    }
  }, [isAuth]);

    useEffect(() => {
      if (isPin === true || isPinSet === true) {
        setPinSet(true);
      } else {
        setPinSet(false);
      }
    }, [isPin]);


  const link = [
    {
      id: 1,
      title: "Dashboard",
      icon: <Home2 size="20" />,
      route: "/dashboard",
    },
    {
      id: 2,
      title: "Trade Gift Card",
      icon: <Card size="20" />,
      route: "/trade",
    },
    { id: 3, title: "Bills", icon: <Home2 size="20" />, route: "/bills" },
    {
      id: 4,
      title: "Wallets",
      icon: <EmptyWallet size="20" />,
      route: "/wallets",
    },
    {
      id: 5,
      title: "Transactions",
      icon: <ArrowRotateRight size="20" />,
      route: "/transactions",
    },
    {
      id: 6,
      title: "Settings",
      icon: <Setting2 size="20" />,
      route: "/settings",
    },
  ];

  return (
    <div
      style={{
        left: openSidebar === true ? "0px" : "-251px",
        maxWidth: "250px",
      }}
      className={style.sidebar}
    >
      <div className={style.sb}>
        <div className={style.top}>
          {/* Astro Logo */}
          <div className={style.logo}>
            <img src={logo} alt="astroafrica.site" />
          </div>

          <CloseSquare
            className={style.mobClose}
            onClick={() => {
              setOpenSidebar(false);
            }}
            size="32"
            color="#40196D"
            variant="Outline"
          />
        </div>
        <div className={style.listWrap}>
          <div className={style.list}>
            <div
              onClick={() => {
                setOpenSidebar(false);
                navigate("/dashboard");
              }}
              className={
                window.location.pathname === "/dashboard" ||
                window.location.pathname === "/dashboard/personal-info" ||
                window.location.pathname === "/dashboard/account-details" ||
                window.location.pathname === "/dashboard/transaction-pin"
                  ? style.buttonHi
                  : style.button
              }
            >
              <Home2 size="20" />
              <p>Dashboard</p>
            </div>

            <div
              onClick={() => {
                setOpenSidebar(false);
                navigate("/trade");
              }}
              className={
                window.location.pathname === "/trade" ||
                window.location.pathname === `/trade/${paramName}`
                  ? style.buttonHi
                  : style.button
              }
            >
              <Card size="20" />
              <p>Trade Gift Cards</p>
            </div>
            
            <div
              onClick={() => {
                setOpenSidebar(false);
                navigate("/crypto");
              }}
              className={
                window.location.pathname === "/crypto" ||
                window.location.pathname === `/crypto/${paramName}`
                  ? style.buttonHi
                  : style.button
              }
            >
              <Card size="20" />
              <p>Sell Crypto</p>
            </div>

            <div
              onClick={() => {
                setOpenSidebar(false);
                navigate("/exchange");
              }}
              className={
                window.location.pathname === "/exchange"
                  ? style.buttonHi
                  : style.button
              }
            >
              <ConvertCard size="20" />
              <p>Exchange</p>
            </div>

            <div
              onClick={() => {
                setOpenSidebar(false);
                navigate("/fund-wallet");
              }}
              className={
                window.location.pathname === "/fund-wallet"
                  ? style.buttonHi
                  : style.button
              }
            >
              <Wallet size="20" />
              <p>Fund Wallet</p>
            </div>

            <div
              onClick={() => {
                setOpenSidebar(false);
                navigate("/transactions");
                // dispatch(getDepositTransactions());
              }}
              className={
                window.location.pathname === "/transactions"
                  ? style.buttonHi
                  : style.button
              }
            >
              <ArrowRotateRight size="20" />
              <p>Transactions</p>
            </div>

            {/* <div
              onClick={() => {
                setOpenSidebar(false);
                navigate("/bills");
              }}
              className={
                window.location.pathname === "/bills" ||
                window.location.pathname === "/bills/airtime" ||
                window.location.pathname === "/bills/data" ||
                window.location.pathname === "/bills/electricity"
                  ? style.buttonHi
                  : style.button
              }
            >
              <Bill size="20" />
              <p>Bills</p>
            </div> */}

            <div
              onClick={() => {
                setOpenSidebar(false);
                navigate("/wallets");
              }}
              className={
                window.location.pathname === "/wallets"
                  ? style.buttonHi
                  : style.button
              }
            >
              <Wallet3 size="20" />
              <p>Wallets</p>
            </div>

            <div
              onClick={() => {
                setOpenSidebar(false);
                navigate("/calculator");
              }}
              className={
                window.location.pathname === "/calculator"
                  ? style.buttonHi
                  : style.button
              }
            >
              <Calculator size="20" />
              <p>Calculator</p>
            </div>

            {/* <div
              onClick={() => {
                setOpenSidebar(false);
                navigate("/referrals");
              }}
              className={
                window.location.pathname === "/referrals"
                  ? style.buttonHi
                  : style.button
              }
            >
              <Profile2User size="20" />
              <p>Referral</p>
            </div> */}

            <div
              id="webSettings"
              onClick={() => {
                setNewRoute("settings");
                setOpenSidebar(false);
                navigate("/settings");
              }}
              className={
                window.location.pathname === "/settings"
                  ? style.webSettingsHi
                  : style.webSettings
              }
            >
              <Setting2 size="20" />
              <p>Settings</p>
            </div>

            <div
              id="mobSettings"
              onClick={() => {
                navigate("/settings");
                setNewRoute("settings");
              }}
              className={
                window.location.pathname === "/settings"
                  ? style.mobSettingsHi
                  : style.mobSettings
              }
            >
              <Setting2 size="20" />
              <p>Settings </p>
            </div>
            <div
              style={{
                height:
                  window.location.pathname === "/settings" ? "200px" : "0px",
              }}
              className={style.settingsDD}
            >
              <p
                className={
                  newRoute === "settings"
                    ? style.settingsDDHip
                    : style.settingsDDp
                }
                onClick={() => {
                  handleSettLinks("settings");
                  setOpenSidebar(false);
                }}
                style={{
                  padding:
                    window.location.pathname === "/settings" ? "12px" : "0px",
                  margin:
                    window.location.pathname === "/settings"
                      ? "4px 2px"
                      : "0px",
                }}
              >
                Profile
              </p>
              <p
                className={
                  newRoute === "crypto"
                    ? style.settingsDDHip
                    : style.settingsDDp
                }
                onClick={() => {
                  handleSettLinks("crypto");
                  setOpenSidebar(false);
                }}
                style={{
                  padding:
                    window.location.pathname === "/settings" ? "12px" : "0px",
                  margin:
                    window.location.pathname === "/settings"
                      ? "4px 2px"
                      : "0px",
                }}
              >
                Crypto Coins
              </p>
              <p
                className={
                  newRoute === "bank" ? style.settingsDDHip : style.settingsDDp
                }
                onClick={() => {
                  handleSettLinks("bank");
                  setOpenSidebar(false);
                }}
                style={{
                  padding:
                    window.location.pathname === "/settings" ? "12px" : "0px",
                  margin:
                    window.location.pathname === "/settings"
                      ? "4px 2px"
                      : "0px",
                }}
              >
                Add Bank Account
              </p>

              {pinSet === false ? (
                <p
                  className={
                    newRoute === "setPin"
                      ? style.settingsDDHip
                      : style.settingsDDp
                  }
                  onClick={() => {
                    handleSettLinks("setPin");
                    setOpenSidebar(false);
                  }}
                  style={{
                    padding:
                      window.location.pathname === "/settings" ? "12px" : "0px",
                    margin:
                      window.location.pathname === "/settings"
                        ? "4px 2px"
                        : "0px",
                  }}
                >
                  Set Pin
                </p>
              ) : (
                <p
                  className={
                    newRoute === "changePin"
                      ? style.settingsDDHip
                      : style.settingsDDp
                  }
                  onClick={() => {
                    handleSettLinks("changePin");
                    setOpenSidebar(false);
                  }}
                  style={{
                    padding:
                      window.location.pathname === "/settings" ? "12px" : "0px",
                    margin:
                      window.location.pathname === "/settings"
                        ? "4px 2px"
                        : "0px",
                  }}
                >
                  Change Pin
                </p>
              )}

              {pinSet === true && (
                <p
                  className={
                    newRoute === "resetPin"
                      ? style.settingsDDHip
                      : style.settingsDDp
                  }
                  onClick={() => {
                    handleSettLinks("resetPin");
                    setOpenSidebar(false);
                  }}
                  style={{
                    padding:
                      window.location.pathname === "/settings" ? "12px" : "0px",
                    margin:
                      window.location.pathname === "/settings"
                        ? "4px 2px"
                        : "0px",
                  }}
                >
                  Forgot/Reset Pin
                </p>
              )}
            </div>

            <div
              onClick={() => {
                setOpenSidebar(false);
                dispatch(logout());
                dispatch(clearProfileImage());
              }}
              className={
                window.location.pathname === "/" ? style.buttonHi : style.button
              }
            >
              <LogoutCurve size="20" />
              <p>Logout</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
