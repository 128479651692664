import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Rates from './components/rates/Rates';
import ResetCompleted from './components/resetComplete/ResetCompleted';
import EmailVerify from './pages/auth/emailVerify/EmailVerify';
import ResetPassword from './pages/auth/forgotPwd/ResetPassword';
import ForgotPassword from './pages/auth/forgotPwd/ResetPassword';
import Login from './pages/auth/login/Login';
import Signup from './pages/auth/signup/Signup';
import Dashboard from './pages/dashboard/Dashboard';
import Home from './pages/home/Home';
import NotFoundPage from './pages/notFoundpage/NotFoundPage';
import DojahComponent from './components/DojahComponent';
import VerifyEmailPage from './pages/auth/emailVerify/VerifyEmail';

function App() {

  const [registered, setRegistered] = useState(false)
  const [verifiedKYC, setVerifiedKYC] = useState(false)
  const [calcRoute, setCalcRoute] = useState("giftCards");

  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signin" element={<Login />} />
        <Route path="reset-password/:userToken" element={<ResetPassword />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/rates"
          element={<Rates calcRoute={calcRoute} setCalcRoute={setCalcRoute} />}
        />
        <Route path="/email-verify" element={<EmailVerify />} />
        <Route path="/verify-email" element={<VerifyEmailPage />} />
        <Route path="/reset-completed" element={<ResetCompleted />} />
        <Route
          path="/dashboard"
          element={
            <Dashboard
              setVerifiedKYC={setVerifiedKYC}
              verifiedKYC={verifiedKYC}
            />
          }
        />
        <Route
          path="/dashboard/personal-info"
          element={
            <Dashboard
              setVerifiedKYC={setVerifiedKYC}
              verifiedKYC={verifiedKYC}
            />
          }
        />
        <Route
          path="/dashboard/account-details"
          element={
            <Dashboard
              setVerifiedKYC={setVerifiedKYC}
              verifiedKYC={verifiedKYC}
            />
          }
        />
        <Route
          path="/dashboard/transaction-pin"
          element={
            <Dashboard
              setVerifiedKYC={setVerifiedKYC}
              verifiedKYC={verifiedKYC}
            />
          }
        />

        <Route
          path="/trade"
          element={
            <Dashboard
              setVerifiedKYC={setVerifiedKYC}
              verifiedKYC={verifiedKYC}
            />
          }
        />
        <Route
          path="/trade/:cardname"
          element={
            <Dashboard
              setVerifiedKYC={setVerifiedKYC}
              verifiedKYC={verifiedKYC}
            />
          }
        />
        <Route
          path="/crypto"
          element={
            <Dashboard
              setVerifiedKYC={setVerifiedKYC}
              verifiedKYC={verifiedKYC}
            />
          }
        />

        <Route
          path="/fund-wallet"
          element={
            <Dashboard
              setVerifiedKYC={setVerifiedKYC}
              verifiedKYC={verifiedKYC}
            />
          }
        />


        <Route
          path="/exchange"
          element={
            <Dashboard
              setVerifiedKYC={setVerifiedKYC}
              verifiedKYC={verifiedKYC}
            />
          }
        />

        <Route
          path="/bills"
          element={
            <Dashboard
              setVerifiedKYC={setVerifiedKYC}
              verifiedKYC={verifiedKYC}
            />
          }
        />
        <Route
          path="/bills/cable"
          element={
            <Dashboard
              setVerifiedKYC={setVerifiedKYC}
              verifiedKYC={verifiedKYC}
            />
          }
        />
        <Route
          path="/bills/airtime"
          element={
            <Dashboard
              setVerifiedKYC={setVerifiedKYC}
              verifiedKYC={verifiedKYC}
            />
          }
        />
        <Route
          path="/bills/data"
          element={
            <Dashboard
              setVerifiedKYC={setVerifiedKYC}
              verifiedKYC={verifiedKYC}
            />
          }
        />
        <Route
          path="/bills/electricity"
          element={
            <Dashboard
              setVerifiedKYC={setVerifiedKYC}
              verifiedKYC={verifiedKYC}
            />
          }
        />

        <Route
          path="/wallets"
          element={
            <Dashboard
              setVerifiedKYC={setVerifiedKYC}
              verifiedKYC={verifiedKYC}
            />
          }
        />
        <Route
          path="/transactions"
          element={
            <Dashboard
              setVerifiedKYC={setVerifiedKYC}
              verifiedKYC={verifiedKYC}
            />
          }
        />

        <Route
          path="/referrals"
          element={
            <Dashboard
              setVerifiedKYC={setVerifiedKYC}
              verifiedKYC={verifiedKYC}
            />
          }
        />
        <Route
          path="/calculator"
          element={
            <Dashboard
              setVerifiedKYC={setVerifiedKYC}
              verifiedKYC={verifiedKYC}
              calcRoute={calcRoute}
              setCalcRoute={setCalcRoute}
            />
          }
        />
        <Route
          path="/settings"
          element={
            <Dashboard
              setVerifiedKYC={setVerifiedKYC}
              verifiedKYC={verifiedKYC}
            />
          }
        />
        <Route
          path="/verify-address"
          element={
            <DojahComponent
              setVerifiedKYC={setVerifiedKYC}
              verifiedKYC={verifiedKYC}
            />
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
}

export default App;
