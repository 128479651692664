import { Icon } from "@iconify/react";
import {
  Briefcase,
  BuyCrypto,
  Calendar1,
  Card,
  CloseCircle,
  DollarCircle,
  Receipt21,
  SearchNormal1,
  Setting4,
  Wallet1,
} from "iconsax-react";
import React, { useState } from "react";
import Cash from "./Cash";
import GiftCards from "./GiftCards";
import style from "./transactions.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOutwardCryptoTransactions,
  getDepositTransactions,
  getGiftcardTransactions,
  getP2PFiatTransactions,
  getWithdrawalTransactions,
} from "../../store/transactions/transActions";
import prompt from "../../assets/images/prompt.svg";
import { useEffect } from "react";
import Lottie from "lottie-react";
import loader from "../../assets/lottie/loader.json";
import { setHeader } from "../../store/settings/settingsSlice";
import Modal from "../modal/Modal";
import {
  clearDepositById,
  searchTransactions,
} from "../../store/transactions/transSlice";
import moment from "moment";
import Crypto from "./Crypto";
import { fetchCryptoTransactions } from "../../store/crypto/cryptoActions";

const Transaction = () => {
  let PageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const [location, setLocation] = useState("transactions");
  const [cashLocation, setCashLocation] = useState("deposit");
  const [transBarOpen, setTransBarOpen] = useState(false);
  const [transBarLoading, setTransBarLoading] = useState(false);
  const [currNo, setCurrNo] = useState(0);
  const [transCat, setTransCat] = useState("cash");
  const [transCatOpen, setTransCatOpen] = useState(false);
  const [picModal, setPicModal] = useState(false);
  const [imgSrc, setImgSrc] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const dispatch = useDispatch();
  const cashTransactions = useSelector((state) => state.trans.transactions);
  const isTrLoading = useSelector((state) => state.trans.trLoading);
  const transDetails = useSelector((state) => state.trans.depositById);
  const walletData = useSelector((state) => state.wallet.data);
  const userData = useSelector((state) => state.auth.fullUser);

  const getMoreItemsByOffset = (page) => {
    // setCurrentPage(page);
  };

  const formatTagName = (value) => {
    let res;
    switch (value) {
      case "Wallet withdrawer":
        res = "Wallet Withdrawal";
        break;
      default:
        break;
    }
    return res;
  };

  const setTr = () => {
    dispatch(getDepositTransactions());
    setLocation("transactions");
    setCashLocation("deposit");
    setTransBarOpen(false);
    dispatch(clearDepositById());
  };

  const setGC = () => {
    setLocation("giftcards");
    setCashLocation("deposit");
    setTransBarOpen(false);
    dispatch(getGiftcardTransactions());
    dispatch(clearDepositById());
  };

  const setCrypto = () => {
    setLocation("crypto");
    setCashLocation("deposit");
    setTransBarOpen(false);
    dispatch(fetchCryptoTransactions());
    dispatch(clearDepositById());
  };

  useEffect(() => {
    dispatch(setHeader("Transactions"));
  }, []);

  useEffect(() => {
    if (isTrLoading === true) {
      setTransBarLoading(true);
    } else {
      setTransBarLoading(false);
    }
  }, [isTrLoading]);

  useEffect(() => {
    dispatch(getDepositTransactions());
    dispatch(clearDepositById());
  }, []);

  // const search = () => {
  //   dispatch(searchTransactions(searchInput))
  // };

  return (
    <div className={style.transaction}>
      <Modal modalOpen={picModal} setModalOpen={setPicModal} canClose={true}>
        <h5 style={{ width: "100%", textAlign: "center" }}>Uploaded Card</h5>
        <div className={style.pictModal}>
          <img
            src={imgSrc ? imgSrc : undefined}
            alt={`astroafrica.site/${imgSrc}`}
          />
        </div>
      </Modal>

      {/* ============== THIS IS THE TRANSACTION SIDEBAR FOR EVERY TRANSACTION PAGE */}
      <div
        style={{ right: transBarOpen === true ? "0" : "-380px" }}
        className={style.transBar}
      >
        <div className={style.transWrap}>
          <div className={style.twTop}>
            <h5>Transaction Details</h5>
            <CloseCircle
              onClick={() => {
                setTransBarOpen(false);
                dispatch(clearDepositById());
              }}
              className={style.copyCon}
            />
          </div>
          <div className={style.twBottom}>
            {transBarLoading === true ? (
              <div className={style.lottieWrap}>
                <Lottie
                  style={{
                    width: "70px",
                    height: "70px",
                    color: "#fff",
                    padding: "0px",
                    margin: "0px",
                  }}
                  animationData={loader}
                  loop={true}
                />
              </div>
            ) : (
              <>
                {transDetails.id ? (
                  <div className={style.twbWrap}>
                    <div className={style.tdTop}>
                      <div className={style.tdtImage}>
                        <img
                          src={
                            transDetails.GiftCard
                              ? transDetails.GiftCard.image
                              : prompt
                          }
                          alt="astroafrica.site/prompt.svg"
                        />
                      </div>
                      <h3>
                        {transDetails.GiftCard &&
                          new Intl.NumberFormat("NG", {
                            style: "currency",
                            currency: "USD",
                          }).format(transDetails.amount)}

                        {
                        transDetails.flw_data &&
                        `${
                          walletData?.fiatWallets?.find(wallet => 
                            transDetails?.currency_id === wallet.Currency.id
                        )?.Currency.code} ${transDetails.amount}`
                        }
                         {
                          transDetails.flw_data === null &&
                          `${
                            walletData?.fiatWallets?.find(wallet => 
                              transDetails?.currency_id === wallet.Currency.id
                          )?.Currency.code} ${transDetails.amount}`
                         }
                        
                        {transDetails.PaymentOutwards &&
                              `${walletData?.fiatWallets?.find(wallet => 
                                transDetails?.fiat_currency_id === wallet.Currency.id
                              )?.Currency.code} ${transDetails.amount}`
                          }
                        {transDetails.CoinType &&
                          new Intl.NumberFormat("NG", {
                            style: "currency",
                            currency: transDetails.CreditCurrency.code,
                          }).format(transDetails.payout_amount)}

                          {transDetails.CryptoAccount &&
                            new Intl.NumberFormat("NG", {
                              style: "currency",
                              currency: "USD",
                            }).format(transDetails.amount)}
                      </h3>
                      <p>
                        {transDetails.GiftCard
                          ? transDetails.GiftCard.name + " giftcard"
                          : transDetails.tag}
                      </p>
                      {transDetails.CryptoAccount
                                  && transDetails.CryptoAccount.coin_name
                      }
                      <p>
                        {transDetails.PaymentOutwards &&
                        transDetails.PaymentOutwards[0] ? (
                          transDetails.PaymentOutwards[0].reference
                        ) : (
                          <p>
                            {transDetails.GiftCard ? (
                              transDetails.reference
                            ) : (
                              <>
                                {" "}
                                {transDetails.CoinType
                                  ? transDetails.reference
                                  : transDetails.reference}
                              </>
                            )}
                          </p>
                        )}

                      {transDetails.CryptoAccount &&  
                          transDetails.CryptoAccount.wallet_address
                        }
                      </p>
                    </div>
                    {transDetails.status === "DONE" && (
                      <div className={style.success}>Success</div>
                    )}
                    {transDetails.status === "SUBMITTED" && (
                      <div className={style.pending}>Pending</div>
                    )}
                      {transDetails.status === "PROCESSING" && (
                      <div className={style.pending}>Processing</div>
                    )}
                    {transDetails.status === "FAILED" && (
                      <div className={style.failed}>Declined</div>
                    )}

                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "14px",
                      }}
                    >
                      {transDetails.comments ? transDetails.comments : ""}
                    </div>
                    <div className={style.tdBottom}>
                      <div className={style.tdItemWrap}>
                        {transDetails.comments ? (
                          <div className={style.labelItem}>
                            {/* <Briefcase size="20" /> */}
                            {/* <p>Comment</p> */}
                          </div>
                        ) : (
                          ""
                        )}
                        <p className={style.labelDets}></p>
                      </div>
                      <div className={style.tdItemWrap}>
                        <div className={style.labelItem}>
                          <Briefcase size="20" />
                          <p>
                            {transDetails.GiftCard && "Category"}
                            {transDetails.tag === "Fund Wallet" && "Recipient"}
                            {transDetails.PaymentOutwards &&
                            transDetails.PaymentOutwards[0]
                              ? formatTagName(
                                  transDetails.PaymentOutwards[0].tag
                                )
                              : ""}
                            {transDetails.CoinType && "Address"}
                          </p>
                        </div>
                        <p className={style.labelDets}>
                          {/* {transDetails.GiftCard && transDetails && transDetails.GiftCard.name} */}
                          {userData && transDetails.GiftCard ? (
                            transDetails.GiftCard.name
                          ) : (
                            <>
                              {transDetails.CoinType
                                ? transDetails.wallet_address
                                : userData.username}
                            </>
                          )}
                        </p>
                      </div>
                      <div className={style.tdItemWrap}>
                        <div className={style.labelItem}>
                          <Calendar1 size="20" />
                          <p>Date</p>
                        </div>
                        <p className={style.labelDets}>
                          {transDetails.created_at
                            ? moment(transDetails.created_at).format(
                                "Do, MMM YYYY hh:mm:ss a"
                              )
                            : ""}
                        </p>
                      </div>
                      <div className={style.tdItemWrap}>
                        <div className={style.labelItem}>
                          <DollarCircle size="20" />
                          <p>Amount</p>
                        </div>
                        <p className={style.labelDets}>
                          {transDetails.GiftCard &&
                            new Intl.NumberFormat("NG", {
                              style: "currency",
                              currency: "USD",
                            }).format(transDetails.amount)}

                              {
                                transDetails.flw_data &&
                                `${
                                  walletData?.fiatWallets?.find(wallet => 
                                    transDetails?.currency_id === wallet.Currency.id
                                )?.Currency.code} ${transDetails.amount}`
                                }

                                {
                                transDetails.flw_data === null &&
                                `${
                                  walletData?.fiatWallets?.find(wallet => 
                                    transDetails?.currency_id === wallet.Currency.id
                                )?.Currency.code} ${transDetails.amount}`
                              }

                              {transDetails.PaymentOutwards &&
                                  `${walletData?.fiatWallets?.find(wallet => 
                                    transDetails?.fiat_currency_id === wallet.Currency.id
                                  )?.Currency.code} ${transDetails.amount}`
                              }
                          {transDetails.CoinType &&
                            new Intl.NumberFormat("NG", {
                              style: "currency",
                              currency: "USD",
                            }).format(transDetails.amount)}

                            {transDetails.CryptoAccount &&
                            new Intl.NumberFormat("NG", {
                              style: "currency",
                              currency: "USD",
                            }).format(transDetails.amount)}

                        
                        </p>
                      </div>
                      {/* <div className={style.tdItemWrap}>
                        <div className={style.labelItem}>
                          <Receipt21 size="20" />
                          <p>Reference ID</p>
                        </div>
                        <p className={style.labelDets}>
                          {transDetails.PaymentOutwards[0]
                            ? transDetails.PaymentOutwards[0].reference
                            : "no reference available"}
                        </p>
                      </div> */}
                      <div className={style.cardPegs}>
                        {transDetails.images &&
                          transDetails.images.map((item, idx) => (
                            <div
                              key={idx}
                              onClick={() => {
                                setPicModal(true);
                                setImgSrc(item);
                              }}
                              className={style.cardPeg}
                            >
                              <img src={item} alt="astroafrica.site/card" />
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <p>Oops, an error occurred</p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className={style.top}>{/* <h2>Recent Transactions</h2> */}</div>
      <div className={style.switchWrap}>
        <div className={style.switchText}>
          {/* ======================CASH */}
          <div
            style={{
              color: location === "transactions" ? "#40196D" : "#B1B0AE",
              border:
                location === "transactions"
                  ? "2px #40196d solid"
                  : "2px #B1B0AE solid",
            }}
            onClick={() => {
              setTr();
            }}
            className={style.topBtns}
          >
            <Wallet1 className={style.topCons} />
            <p
              style={{
                color: location === "transactions" ? "#40196D" : "#B1B0AE",
              }}
            >
              Wallet
            </p>
          </div>

          {/* ============================= GIFTCARDS */}
          <div
            style={{
              color: location === "giftcards" ? "#40196D" : "#B1B0AE",
              border:
                location === "giftcards"
                  ? "2px #40196d solid"
                  : "2px #B1B0AE solid",
            }}
            onClick={setGC}
            className={style.topBtns}
          >
            <Card className={style.topCons} />
            <p
              style={{
                color: location === "giftcards" ? "#40196D" : "#B1B0AE",
              }}
            >
              Gift Card
            </p>
          </div>

          {/* ====================== CRYPTO */}
          <div
            style={{
              color: location === "crypto" ? "#40196D" : "#B1B0AE",
              border:
                location === "crypto"
                  ? "2px #40196d solid"
                  : "2px #B1B0AE solid",
            }}
            onClick={setCrypto}
            className={style.topBtns}
          >
            <BuyCrypto className={style.topCons} />
            <p
              style={{
                color: location === "crypto" ? "#40196D" : "#B1B0AE",
              }}
            >
              Crypto
            </p>
          </div>
        </div>
        {/* <div className={style.switchBar}>
          <div
            style={{ left: location === "transactions" ? "0%" : "50%" }}
            className={style.bar}
          ></div>
        </div> */}
      </div>
      <div
        style={{ display: location === "transactions" ? "block" : "none" }}
        className={style.cashTabs}
      >
        <ul>
          <li
            onClick={() => {
              setCashLocation("deposit");
              dispatch(getDepositTransactions());
              setTransBarOpen(false);
              dispatch(clearDepositById());
            }}
            style={{
              borderBottom:
                cashLocation === "deposit"
                  ? "2px #40196D solid"
                  : "0px #90909039 solid",
            }}
          >
            <p>Deposits</p>
          </li>
          <li
            onClick={() => {
              setCashLocation("withdrawals");
              setTransCat("Cash");
              dispatch(getWithdrawalTransactions());
              setTransBarOpen(false);
              dispatch(clearDepositById());
            }}
            style={{
              borderBottom:
                cashLocation === "withdrawals"
                  ? "2px #40196D solid"
                  : "0px #90909039 solid",
            }}
          >
            <p>Withdrawals</p>
          </li>
          <li
            onClick={() => {
              setCashLocation("p2p");
              setTransCat("Cash");
              dispatch(getP2PFiatTransactions());
              setTransBarOpen(false);
            }}
            style={{
              borderBottom:
                cashLocation === "p2p"
                  ? "2px #40196D solid"
                  : "0px #90909039 solid",
            }}
          >
            <p>P2P Fiat Transactions</p>
          </li>
        </ul>
      </div>
      <div className={style.searchFilter}>
        <div className={style.searchPut}>
          <SearchNormal1
            className={style.searchCon}
            size="20"
            color="#1a1a1a"
            variant="Outline"
          />
          <input
            type="text"
            name="search"
            id="search"
            value={searchInput}
            placeholder="Search for transactions"
            onChange={(e) => {
              setSearchInput(e.target.value);
              // search();
            }}
          />
        </div>
        {cashLocation === "withdrawals" ? (
          <div
            onClick={() => {
              setTransCatOpen(!transCatOpen);
            }}
            className={style.filter}
          >
            {transCat}
            <div
              style={{
                height: transCatOpen === true ? "fit-content" : "0px",
              }}
              className={style.filterDD}
            >
              <p
                onClick={() => {
                  setTransCat("Cash");
                }}
                className={style.fDDP}
              >
                {" "}
                Cash
              </p>
              <p
                onClick={() => {
                  setTransCat("Crypto");
                  dispatch(fetchOutwardCryptoTransactions());
                }}
                className={style.fDDP}
              >
                Crypto
              </p>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      {location === "transactions" && (
        <Cash
          transBarOpen={transBarOpen}
          setTransBarOpen={setTransBarOpen}
          cashLocation={cashLocation}
          transactions={cashTransactions}
          searchInput={searchInput}
          currentPage={currentPage}
          getMoreItemsByOffset={getMoreItemsByOffset}
          pageSize={PageSize}
          transCat={transCat}
        />
      )}
      {location === "giftcards" && (
        <GiftCards
          transBarOpen={transBarOpen}
          setTransBarOpen={setTransBarOpen}
          transactions={cashTransactions}
          searchInput={searchInput}
          currentPage={currentPage}
          pageSize={PageSize}
        />
      )}
      {location === "crypto" && (
        <Crypto
          transBarOpen={transBarOpen}
          setTransBarOpen={setTransBarOpen}
          transactions={cashTransactions}
          searchInput={searchInput}
          currentPage={currentPage}
          pageSize={PageSize}
        />
      )}
    </div>
  );
};

export default Transaction;
