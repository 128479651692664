import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setHeader } from '../../store/settings/settingsSlice';
import style from "./exchange.module.css";
import { ArrowDown2 } from "iconsax-react";
import info_icon from '../../assets/images/info_icon.svg'
import nigeria_icon from '../../assets/images/nigeria.svg'
import ghana_icon from '../../assets/images/ghanaflag.svg'
import usd_icon from '../../assets/images/usd.svg'
import successIcon from '../../assets/images/success-icon.svg'
import spin_circle from '../../assets/images/Loader anim.png'
import Modal from '../modal/Modal';
import { fetchExchangeConfig, InitiateExchange } from '../../store/wallet/walletActions';
import PinInput from "react-pin-input";


const ExchangePage = () => {

    const dispatch = useDispatch()
    const walletData = useSelector((state) => state.wallet.data);
    const isPinSet = useSelector((state) => state.auth.pinData.isSetPin);
    const exchangeConfig = useSelector((state) => state.wallet.exchangeConfig);
    const [openFromWallet, setOpenFromWallet] = useState(false);
    const [openToWallet, setOpenToWallet] = useState(false);
    const [sourceWallet, setSourceWallet] = useState("");
    const [destinationWallet, setDestinationWallet] = useState("");
    const [isSuccessModal, setSuccessModal] = useState(false);
     const [isOpen, setIsOpen] = useState(false);
     const [isPinModal, setPinModal] = useState(false);
      const [isPin, setIsPin] = useState(false);
    const [trPin, setTrPin] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [amount, setAmount] = useState("");
    const [exchangeRate, setExchangeRate] = useState(0);
    const [receiveAmount, setReceiveAmount] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [currencyExchangeSettingsId, setCurrencyExchangeSettingsId] = useState(null)
      let ele = useRef();
    


      // Format number with commas
    const formatNumberWithCommas = (value) =>
      new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
  

    const handleFromWallet = (e) => {
      const selectedCurrency = e.target.textContent;
        setOpenFromWallet(false);
        setSourceWallet(selectedCurrency);
        setReceiveAmount("")
        setAmount("")
        setErrorMessage("")
        setExchangeRate(0)
       // Reset the destination wallet if it matches the selected source wallet
      if (destinationWallet === selectedCurrency) {
        setDestinationWallet("");
      }
    };
    const handleToWallet = (e) => {
      const selectedCurrency = e.target.textContent;
        setOpenToWallet(false);
        setDestinationWallet(selectedCurrency);
        setReceiveAmount("")
        setAmount("")
        setErrorMessage("")
        setExchangeRate(0)
          // Reset the source wallet if it matches the selected destination wallet
        if (sourceWallet === selectedCurrency) {
          setSourceWallet("");
        }
    }

      // Filter the wallets for the From dropdown
    const filteredFromWallets = walletData.fiatWallets?.filter(
      (item) => item.Currency.code !== destinationWallet
    );

    // Filter the wallets for the To dropdown
    const filteredToWallets = walletData.fiatWallets?.filter(
      (item) => item.Currency.code !== sourceWallet
    );

    const toggleFromWallet = () => {
        setOpenFromWallet(!openFromWallet)
    };

    const toggleToWallet = () => {
        setOpenToWallet(!openToWallet)
    };

    const handleAmountChange = (e) => {
      const inputAmount = e.target.value;
      setAmount(inputAmount);
  
      if (!sourceWallet || !destinationWallet) {
        setErrorMessage("Please select both source and destination wallets.");
        setReceiveAmount("");
        return;
      }
  
      setErrorMessage("");
  
      // Filter exchange rates based on selected wallets
      const exchangeRateData = exchangeConfig.find(
        (rate) =>
          rate.BaseCurrency.code === sourceWallet && rate.TargetCurrency.code === destinationWallet
      );
  
      if (exchangeRateData) {
        const exchangeRate = parseFloat(exchangeRateData.exchange_rate);
        setExchangeRate(exchangeRate)
        setCurrencyExchangeSettingsId(exchangeRateData.id)
        const calculatedReceiveAmount = parseFloat(inputAmount) * parseFloat(exchangeRate);
        // setReceiveAmount(calculatedReceiveAmount.toFixed(2)); // Round to 2 decimal places
        setReceiveAmount(
          inputAmount && !isNaN(calculatedReceiveAmount)
            ? formatNumberWithCommas(calculatedReceiveAmount)
            : "0"
        );
      } else {
        setErrorMessage("Exchange rate not found for the selected currencies.");
        setReceiveAmount("");
      }
    };

    const handleExchangeFunds = (e)  =>{
      e.preventDefault();
      setPinModal(true)
    }

    const handleInitiateExchange = () =>{
       setPinModal(false)
       setIsOpen(true)
       dispatch(InitiateExchange({
        amount: parseFloat(amount),
        currencyExchangeSettingsId,
        transactionPin: trPin,
       })).then((res)=>{
        if(res?.type === "InitiateExchange/fulfilled"){
          setIsOpen(false)
          setSuccessModal(true)
          setAmount("")
          setReceiveAmount("")
          setErrorMessage("")
          setExchangeRate(0)
          setCurrencyExchangeSettingsId(null)
          setDestinationWallet("")
          setSourceWallet("")
          setTrPin("")
          ele.clear();
        }
        else if(res?.type === "InitiateExchange/rejected"){
          setIsOpen(false)
          setSuccessModal(false)
          setTrPin("")
          ele.clear();
        }
       })
    }

      useEffect(() => {
        if (isPinSet === true) {
          setIsPin(true);
        } else {
          setIsPin(false);
        }
      }, [isPinSet]);

      useEffect(() => {
        if (trPin !== "" && trPin.length === 5) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      }, [trPin]);

      useEffect(() => {
            dispatch(setHeader("Exchange"));
            dispatch(fetchExchangeConfig())
     }, [dispatch]);

    return ( 
        <>

      {/* ====== Transaction PIN MODAL */}

      <Modal
        canClose={true}
        modalOpen={isPinModal}
        setModalOpen={setPinModal}
      >
          {isPin === true && (
          <div className={style.setTrPin}>
            <h2 className={style.pinHead}>Transaction Pin</h2>
            <p>Please input your transaction pin</p>
            <div className={style.inputs2}>
              <PinInput
                length={5}
                initialValue=""
                secret={true}
                ref={(n) => {
                  ele = n;
                }}
                onChange={(value, index) => {
                  setTrPin(value);
                }}
                type="numeric"
                inputMode="number"
                style={{ padding: "10px", margin: "0 auto 0 auto" }}
                inputStyle={{ border: "1px solid", borderColor: "#909090" }}
                inputFocusStyle={{
                  border: "2px solid",
                  borderColor: "#40196D",
                }}
                onComplete={(value, index) => {
                  setTrPin(value);
                }}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
            </div>
            <button
            style={{marginTop: '25px'}}
              disabled={disabled}
              className={style.save}
              onClick={handleInitiateExchange}
            >
              Initiate Exchange
            </button>
          </div>
        )}
      </Modal>

       {/* =========================== THE LOADING MODAL TO CREATE EXCHANGE  */}
       <Modal
        canClose={false}
        modalOpen={isOpen}
        setModalOpen={setIsOpen}
      >
        <div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '25px'}}>
                <img src={spin_circle} alt="spin circle" className={style.animate_spin} />
            </div>

            <div>
                <h4 style={{color: '#40196D', fontWeight: '600', marginTop: '25px'}}>Initiating Exchange</h4>
             
                <p style={{color: '#B6B6B6', maxWidth:'300px', margin: 'auto', marginTop: '15px'}}>
                  Please wait...
                </p>
               
            </div>
        </div>

        </Modal>

          {/* ======== THE MODAL TO STATE THAT EXCHANGE IS SUCCESSFUL */}
          <Modal
        canClose={true}
        modalOpen={isSuccessModal}
        setModalOpen={setSuccessModal}
      >
        <div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '25px'}}>
                <img src={successIcon} alt="spin circle" />
            </div>

            <div>
                <h4 style={{color: '#40196D', fontWeight: '600', marginTop: '25px'}}>Exchange Successful</h4>
            
                <p style={{color: '#B6B6B6', maxWidth:'300px', margin: 'auto', marginTop: '15px'}}>
                Your wallet has been credited successfully.
                </p>
            </div>

            <button onClick={() => setSuccessModal(false)} style={{marginTop: '20px'}} className={style.save}>
               Continue
            </button>

        </div>

        </Modal>
        
            <div style={{marginTop: '30px'}}>  
                <h5 style={{color: '#1A1A1A', fontWeight: 700, fontSize:14}}>Exchange Details</h5>
                <h6 style={{color: '#1A1A1A', fontSize:13, marginTop: '5px', fontWeight:400}}>Exchange money from one currency to another</h6>
            </div>

            <div className={style.exchangeDiv}>
            <form action="">
           
              <div
              >
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <label htmlFor="from_wallet">From Wallet</label>
                 {sourceWallet ? 
                  <p style={{margin: '0px', color: '#40196D', fontWeight: 600, fontSize: 14}}>Bal: {walletData.fiatWallets.find(wallet => sourceWallet.includes(wallet.Currency.code)).balance}</p> : null}
                </div>
                <div className={style.select}>
                  <div id="from_wallet"
                    onClick={toggleFromWallet}
                    name="from_wallet" className={style.selectInput}>
                     <div className={style.walletDiv}>
                       {
                        sourceWallet 
                        &&
                        <img
                        style={{ width: "20px", height: '20px', borderRadius: '50%'}}
                         src={sourceWallet === "NGN" ? nigeria_icon:  sourceWallet === "GHS" ? ghana_icon : sourceWallet === "USD" ? usd_icon: ""} alt="country icon" 
                        />
                        }
                        <p style={{fontWeight: `${sourceWallet ? 600: 400}`}} className={style.walletTitle}>{sourceWallet ? sourceWallet : 'Select wallet'}</p>
                     </div>
                  
                    <ArrowDown2
                      style={{
                        transform:
                          openFromWallet === true
                            ? "translateY( -50%) rotate(-540deg)"
                            : "translateY( -50%) rotate(0deg)",
                        pointerEvents: "none",
                      }}
                      className={style.dropDown}
                      size="20"
                      color="#909090"
                      variant="Bold"
                    />
                  </div>
                  <div
                    style={{
                      height: openFromWallet === true ? "fit-content" : "0px",
                    }}
                    className={style.option}
                  >
                    {filteredFromWallets ? (
                     filteredFromWallets.map((item, index) => (
                        <p
                        id={item.Currency.id}
                        key={item.id}
                          style={{
                              borderBottom: filteredFromWallets.length - 1 === index ? "none" : "1px #90909039 solid;",
                          }} 
                          onClick={(e) => {
                            handleFromWallet(e);
                          }}
                        >
                          {item.Currency.code}
                        </p>
                      ))
                    ) : (
                      <p>Sorry, no wallets here</p>
                    )}
                  </div>
                </div>
              </div>

              <div 
                style={{marginTop: "20px"}}
                 >
                <label htmlFor="amount">Amount<span style={{color: '#FF0000'}}>*</span> </label>
                <div className={style.input}>
                  <input
                    type="number"
                    name="amount"
                    placeholder="Enter amount to exchange" 
                    value={amount}
                   onChange={handleAmountChange}                 
                  />
                {errorMessage && <p style={{ color: "#FF0000", fontSize: '12px', marginTop: '5px' }}>{errorMessage}</p>}
              </div>
            </div>


              <div
              style={{marginTop: "20px"}}
              >
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <label htmlFor="to_wallet">To Wallet</label>
                 {destinationWallet ? 
                  <p style={{margin: '0px', color: '#40196D', fontWeight: 600, fontSize: 14}}>Bal: {walletData.fiatWallets.find(wallet => destinationWallet.includes(wallet.Currency.code)).balance}</p> : null}
                </div>
                <div className={style.select}>
                  <div id="to_wallet"
                    onClick={toggleToWallet}
                    name="to_wallet" className={style.selectInput}>
                   <div className={style.walletDiv}>
                       {
                        destinationWallet 
                        &&
                        <img
                        style={{ width: "20px", height: '20px', borderRadius: '50%'}}
                         src={destinationWallet === "NGN" ? nigeria_icon:  destinationWallet === "GHS" ? ghana_icon : destinationWallet === "USD" ? usd_icon: ""} alt="country icon" 
                        />
                        }
                        <p style={{fontWeight: `${destinationWallet ? 600: 400}`}} className={style.walletTitle}>{destinationWallet ? destinationWallet : 'Select wallet'}</p>
                     </div>
                    <ArrowDown2
                      style={{
                        transform:
                          openToWallet === true
                            ? "translateY( -50%) rotate(-540deg)"
                            : "translateY( -50%) rotate(0deg)",
                        pointerEvents: "none",
                      }}
                      className={style.dropDown}
                      size="20"
                      color="#909090"
                      variant="Bold"
                    />
                  </div>
                  <div
                    style={{
                      height: openToWallet === true ? "fit-content" : "0px",
                    }}
                    className={style.option}
                  >
                    {filteredToWallets ? (
                      filteredToWallets.map((item, index) => (
                        <p
                          id={item.Currency.id}
                            key={item.id}
                          style={{
                              borderBottom: filteredToWallets.length - 1 === index ? "none" : "1px #90909039 solid;",
                          }} 
                          onClick={(e) => {
                            handleToWallet(e);
                          }}
                        >
                          {item.Currency.code}
                        </p>
                      ))
                    ) : (
                      <p>Sorry, no wallets here</p>
                    )}
                  </div>
                </div>
              </div>

             {exchangeRate ?
                <div className={style.info_div}>
                    <img src={info_icon} alt="info icon" />
                    <p style={{margin: '0px', color: '#40196D'}}>1 {sourceWallet} = {exchangeRate} {destinationWallet}</p>
                </div>:
                null
                }

              <div 
                style={{marginTop: "20px"}}
                 >
                <label htmlFor="receiveAmt">You Receive</label>
                <div className={style.input}>
                  <input
                    type="text"
                    name="receiveAmt"
                    disabled
                    value={`${destinationWallet} ${receiveAmount ? receiveAmount : 0}`}
                    placeholder="Amount in preferred currency e.g 10 USD"                  
                  />
                </div>
            </div>
            
            <div style={{marginTop: '30px'}}>
                <button
                className={style.save}
                disabled={!amount || !sourceWallet || !destinationWallet}
                onClick={(e) => {
                  handleExchangeFunds(e);
                }}
              >
                Proceed
              </button>
              </div>

            </form>


            </div>
        </>
     );
}
 
export default ExchangePage;