import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../config";
import cogoToast from "cogo-toast";
import { openDmOpen } from "./walletSlice";
import { getKycSettingsById, getKycStatusById, getUserDailyWithdrawal } from "../KYC/kycAction";

// ================================================================= CREATE WALLET

export const createWallet = createAsyncThunk(
  "createWallet",
  async (values, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    try {
      const res = await axios.post(
        `${baseUrl}/user-account/${id}/create-fiat-wallet`,
        {
          fiatCurrencyId: values.fiatWalletId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": getState().auth.token.toString(),
          },
        }
      );
      if (res.status === 200) {
        console.log(res);
        cogoToast.success("Wallet Added Successfully");
        dispatch(getWalletBalance());
        return res.data;
      }
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// ================================================================= GET WALLET BALANCE

export const getWalletBalance = createAsyncThunk(
  "getWalletBalance",
  async (arg, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    // const currency = getState().auth.currency;
    console.log(id);
    try {
      const res = await axios.get(`${baseUrl}/wallets/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": getState().auth.token.toString(),
        },
      });
      if (res.status === 200) {
        console.log(res);
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        // dispatch({ type: "auth/Logout" });
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

// ================================================================= FETCH VIRTUAL ACCOUNTS =================================================================
export const fetchVirtualAccount = createAsyncThunk(
  "fetchVirtualAccount",
  async ({currency}, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    try {
      const res = await axios.get(`${baseUrl}/payments/virtual-account/user/${id}/currency/${currency}`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": getState().auth.token.toString(),
        },
      });
      if (res.status === 200) {
        console.log(res);
        return res.data;
      }
    } catch (error) {
       if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

// ================================================================= CREATE VIRTUAL ACCOUNT =================================================================
export const createVirtualAccount = createAsyncThunk(
  "createVirtualAccount",
  async ({bankCode}, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    try {
      const res = await axios.post(
        `${baseUrl}/payments/virtual-account/${id}/create`,
         {
          bankCode: bankCode
         },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": getState().auth.token.toString(),
          },
        }
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error);
      }
      else{
        cogoToast.error(`${error.response.data.message}`);
        return rejectWithValue(error);
      }
    }
  }
);

// ================================================================= FETCH  VIRTUAL BANKS =================================================================
export const fetchVirtualBanks = createAsyncThunk(
  "fetchVirtualBanks",
  async (args, { rejectWithValue, dispatch, getState }) => {
    try {
      const res = await axios.get(`${baseUrl}/payments/virtual-account/banks/available`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": getState().auth.token.toString(),
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
       if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

// ================================================================= INITIATE TO FUND GHS =================================================================
export const InitiateDepositAccount = createAsyncThunk(
  "InitiateDepositAccount",
  async ({currencyId,amount}, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    try {
      const res = await axios.post(
        `${baseUrl}/payments/inwards/${id}`,
         {
          currencyId: currencyId,
          amount: amount,
         },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": getState().auth.token.toString(),
          },
        }
      );
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error);
      }
      else{
        cogoToast.error("Ooops, an error occurred while initiating a deposit account!");
        return rejectWithValue(error);
      }
    }
  }
);

// ================================================================= FUND WALLET BALANCE
export const fundWallet = createAsyncThunk(
  "fundWallet",
  async (values, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    // const currency = getState().auth.currency;
    try {
      const res = await axios.post(
        `${baseUrl}/payments/inwards/${id}`,
        {
          currencyId: parseInt(values.currId),
          amount: values.amount,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": getState().auth.token.toString(),
          },
        }
      );
      console.log(values.currId);
      if (res.status === 201) {
        window.open(res.data.data.transaction.data.link, "_blank");
        console.log(res.data.data.transaction.data.link);
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        // dispatch({ type: "auth/Logout" });
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

// ================================================================= WITHDRAW TO BANK ACCOUNT

export const withdrawToBank = createAsyncThunk(
  "withdrawToBank",
  async (
    { bankAcctId, fiatWalletId, trPin, amount },
    { rejectWithValue, dispatch, getState }
  ) => {
    const id = getState().auth.user.user_id;
    // const currency = getState().auth.currency;
    try {
      const res = await axios.put(
        `${baseUrl}/payments/outwards/${id}`,
        {
          bankAccountId: bankAcctId,
          fiatWalletId: fiatWalletId,
          transactionPin: trPin,
          amount: amount,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": getState().auth.token.toString(),
          },
        }
      );
      if (res.status === 200 || res.status === 201) {
        cogoToast.success(
          "Your withdrawal request has been made successfully",
          {
            position: "top-right",
          }
        );
        dispatch(getWalletBalance());
        dispatch(getUserDailyWithdrawal());
        dispatch(getKycStatusById());
        dispatch(getKycSettingsById());
        setTimeout(() => {
          dispatch(openDmOpen());
        }, 2000);
        console.log(res);
        return res;
      }
      return res;
    } catch (error) {
      if (
        error.response.status === 400 &&
        error.response.data.message ===
          "Error while performing transaction: Minimum withdrawal withdrawer amount exceeded"
      ) {
        console.log(error);
        cogoToast.error("Oops! Withdrawal below minimum.", {
          position: "top-right",
        });
        return rejectWithValue(error.response);
      } else if (
        error.response.status === 400 &&
        error.response.data.message ===
          "Error while performing transaction: Maximum withdrawal withdrawer amount exceeded"
      ) {
        cogoToast.error("Oops! Max withdrawal exceeded.");
        return rejectWithValue(error.response);
      } else if (
        error.response.status === 400 &&
        error.response.data.message ===
          "Transaction Pin invalid to carry out NGN withdrawal request"
      ) {
        cogoToast.error("Please check that the pin entered is correct");
        return rejectWithValue(error.response);
      } else if (
        error.response.status === 400 &&
        error.response.data.message ===
          "Transaction Pin invalid to carry out GHS withdrawal request"
      ) {
        cogoToast.error("Please check that the pin entered is correct");
        return rejectWithValue(error.response);
      } else if (error.response.status === 410) {
        cogoToast.error(
          "Your wallet balance is insufficient to make that withdrawal",
          {
            position: "top-right",
          }
        );
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

// ================================================================= GET FIAT CURRENCIES

export const getFiatCurrencies = createAsyncThunk(
  "getFiatCurrencies",
  async (args, { rejectWithValue, dispatch, getState }) => {
    // const currency = getState().auth.currency;
    try {
      const res = await axios.get(`${baseUrl}/misc/fiat-currencies`);
      if (res.status === 200 || res.status === 201) {
        console.log(res);
        return res;
      }
      return res;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      }
      return rejectWithValue(error.response);
    }
  }
);

// ================================================================= WITHDRAW TO BANK ACCOUNT

export const withdrawCrypto = createAsyncThunk(
  "withdrawCrypto",
  async (
    { bankAcctId, fiatWalletId, trPin, amount },
    { rejectWithValue, dispatch, getState }
  ) => {
    const id = getState().auth.user.user_id;
    // const currency = getState().auth.currency;
    try {
      const res = await axios.post(
        `${baseUrl}/coin/withdraw/${id}`,
        {
          cryptoAccountId: bankAcctId,
          fiatWalletId: fiatWalletId,
          transactionPin: trPin,
          amount: amount,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": getState().auth.token.toString(),
          },
        }
      );
      if (res.status === 200 || res.status === 201) {
        cogoToast.success(
          "Your withdrawal request has been made successfully",
          {
            position: "top-right",
          }
        );
        dispatch(getWalletBalance());
        dispatch(getUserDailyWithdrawal());
        console.log(res);
        return res;
      }
      return res;
    } catch (error) {
      if (
        error.response.status === 400 &&
        error.response.data.message ===
          "Error while performing transaction: Minimum withdrawal withdrawer amount exceeded"
      ) {
        console.log(error);
        cogoToast.error("Oops!... Withdrawal below minimum.", {
          position: "top-right",
        });
        return rejectWithValue(error.response);
      }
      if (
        error.response.status === 400 &&
        error.response.data.message ===
          "Error while performing transaction: Maximum withdrawal withdrawer amount exceeded"
      ) {
        cogoToast.error("Oops!... Max withdrawal exceeded.");
        return rejectWithValue(error.response);
      } else if (
        error.response.status === 400 &&
        error.response.data.message ===
          "Transaction Pin invalid to carry out NGN withdrawal request"
      ) {
        cogoToast.error("Please check that the pin entered is correct");
        return rejectWithValue(error.response);
      } else if (
        error.response.status === 400 &&
        error.response.data.message ===
          "Transaction Pin invalid to carry out USD withdrawal request"
      ) {
        cogoToast.error("Please check that the pin entered is correct");
        return rejectWithValue(error.response);
      } else if (
        error.response.status === 400 &&
        error.response.data.message ===
          "Transaction Pin invalid to carry out GHS withdrawal request"
      ) {
        cogoToast.error("Please check that the pin entered is correct");
        return rejectWithValue(error.response);
      } else if (error.response.status === 410) {
        cogoToast.error(
          "Your wallet balance is insufficient to make that withdrawal",
          {
            position: "top-right",
          }
        );
        return rejectWithValue(error.response);
      } else if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

// ================================================================= GET WITHDRAWAL SETTINGS

export const fetchWithdrawalSettings = createAsyncThunk(
  "fetchWithdrawalSettings",
  async (args, { rejectWithValue, dispatch, getState }) => {
    // const currency = getState().auth.currency;
    try {
      const res = await axios.get(`${baseUrl}/misc/settings/withdrawal`);
      if (res.status === 200 || res.status === 201) {
        console.log(res);
        return res;
      }
      return res;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      }
      return rejectWithValue(error.response);
    }
  }
);


//  ==================================== GET EXCHANGE CURRENCY CONFIG =================================
export const fetchExchangeConfig = createAsyncThunk(
  "fetchExchangeConfig",
  async (args, { rejectWithValue, dispatch, getState }) => {
    // const currency = getState().auth.currency;
    try {
      const res = await axios.get(`${baseUrl}/currency-exchange/config/all`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": getState().auth.token.toString(),
        },
      });
      if (res.status === 200) {
        console.log(res);
        return res;
      }
      return res;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      }
      return rejectWithValue(error.response);
    }
  }
);

// INITIATE EXCHANGE
export const InitiateExchange = createAsyncThunk(
  "InitiateExchange",
  async ({ transactionPin, amount, currencyExchangeSettingsId }, { rejectWithValue, dispatch, getState }) => {
    const id = getState().auth.user.user_id;
    try {
      const res = await axios.post(
        `${baseUrl}/currency-exchange/transact/${id}`,
         {
          transactionPin,
          amount,
          currencyExchangeSettingsId
         },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": getState().auth.token.toString(),
          },
        }
      );
      if (res.status === 201) {
        return res.data;
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error);
      }
      else{
        console.log(error.response.data)
        cogoToast.error(`${error.response.data.message}`);
        return rejectWithValue(error);
      }
    }
  }
);


// ================================================================= GET WITHDRAWAL FEES FOR NAIRA/GHANA
export const fetchWithdrawalFees = createAsyncThunk(
  "fetchWithdrawalFees",
  async ({currency}, { rejectWithValue, dispatch, getState }) => {
    // const currency = getState().auth.currency;
    try {
      const res = await axios.get(`${baseUrl}/misc/settings/${currency}`);
      if (res.status === 200 ) {
        console.log(res);
        return res;
      }
      return res;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/logout" });
        return rejectWithValue(error.response);
      }
      return rejectWithValue(error.response);
    }
  }
);

